import './index.scss';

import { ConfigProvider, Spin } from 'antd';
import ruRU from 'antd/lib/locale-provider/ru_RU';
import block from 'bem-cn';
import React, { CSSProperties, PureComponent } from 'react';

import { ReactNode } from '../../interfaces';

const b = block('modal');

class Modal extends PureComponent<{ loading: boolean }> {
  static Header = ({ title }: { title: string }) => <div className={b('header')}>{title}</div>;

  static Body = ({ children, style }: { children: ReactNode; style?: CSSProperties }) => (
    <div style={style} className={b('body')}>
      {children}
    </div>
  );

  static Footer = ({ children, style }: { children: ReactNode; style?: CSSProperties }) => (
    <div style={style} className={b('footer')}>
      {children}
    </div>
  );

  render() {
    return (
      <ConfigProvider locale={ruRU}>
        <div className={b()}>
          {this.props.loading ? (
            <div className={b('loading')}>
              <Spin />
            </div>
          ) : (
            this.props.children
          )}
        </div>
      </ConfigProvider>
    );
  }
}

export default Modal;
