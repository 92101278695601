import { useEffect, useState } from 'react';

import { API, ICountry } from '../../api';

export const useCountries = () => {
  const [counties, setCounties] = useState<ICountry[]>([]);

  useEffect(() => {
    API.Countries.index().then(setCounties);
  }, []);

  return counties;
};
