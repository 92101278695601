import './index.scss';

import { Layout, Pagination, Table } from 'antd';
import block from 'bem-cn';
import { observer } from 'mobx-react';
import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';

import { store } from '../../store';
import { Footer } from '../footer';
import { Header } from '../header';
import { InterfaceUpdatingMessage } from '../interfaceUpdatingMessage';
import { Sidebar } from '../sidebar';

Table.defaultProps = {
  // @ts-ignore
  scroll: { x: true },
};

Pagination.defaultProps = {
  showSizeChanger: false,
  showQuickJumper: true,
  showTotal: (total) => `Всего записей: ${total}`,
};

const { Content } = Layout;
const b = block('private');

@observer
class Private extends PureComponent {
  render() {
    if (store.user) {
      return (
        <Layout className={b()}>
          <Layout className={b('container').toString()}>
            <Header />
            <Content className={b('content').toString()}>
              <InterfaceUpdatingMessage />
              {this.props.children}
            </Content>
            <Footer />
          </Layout>
          <Sidebar />
        </Layout>
      );
    }

    return <Redirect to="/login" />;
  }
}

export default Private;
