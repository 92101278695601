import { TDayOfWeek, TShareStatus, TTimeFactor, TUserRole, TUserStatus } from '../api';

export const Days: Record<TDayOfWeek, string> = {
  mon: 'Пн',
  tue: 'Вт',
  wed: 'Ср',
  thu: 'Чт',
  fri: 'Пт',
  sat: 'Сб',
  sun: 'Вс',
};

export const TimeFactor: Record<TTimeFactor, string> = {
  minutes: 'минут',
  hours: 'часов',
  days: 'дней',
};

export const SimpleShareStatus: Record<TShareStatus, string> = {
  new: 'Новая',
  auto: 'Автоматическая обработка',
  manual: 'Ручная обработка',
  done: 'Расшарена',
  error: 'Ошибка',
  reject: 'Отклонена',
};

export const ShareStatusColors: Record<TShareStatus, string> = {
  new: '#faad14',
  auto: '#1890ff',
  manual: '#faad14',
  done: '#52c41a',
  error: '#ff4d4f',
  reject: '#ff4d4f',
};

export const ShareStatus: Record<TShareStatus, { title: string; color: string }> = {
  new: {
    title: SimpleShareStatus.new,
    color: ShareStatusColors['new'],
  },
  auto: {
    title: SimpleShareStatus.auto,
    color: ShareStatusColors['auto'],
  },
  manual: {
    title: SimpleShareStatus.manual,
    color: ShareStatusColors['manual'],
  },
  done: {
    title: SimpleShareStatus.done,
    color: ShareStatusColors['done'],
  },
  error: {
    title: SimpleShareStatus.error,
    color: ShareStatusColors['error'],
  },
  reject: {
    title: SimpleShareStatus.reject,
    color: ShareStatusColors['error'],
  },
};

export const UserStatus: Record<TUserStatus, string> = {
  pending: 'На рассмотрении',
  active: 'Активен',
  blocked: 'Заблокирован',
};

export const colors: Record<'green' | 'red' | 'blue' | 'orange' | 'gray' | 'darkGray', string> = {
  green: '#52c41a',
  red: '#ff4d4f',
  blue: '#1890ff',
  orange: '#faad14',
  gray: '#cccccc',
  darkGray: '#777777',
};

export const RoleColor: Record<TUserRole, string> = {
  ROLE_ADMIN: colors.red,
  ROLE_EMPLOYEE: colors.green,
  ROLE_PARTNER: colors.blue,
  ROLE_MANAGER: colors.green,
};

export const AppStatusColor = {
  active: '#52c41a',
  new: '#faad14',
  blocked: '#ff4d4f',
  deleted: 'rgba(0,0,0,.25)',
  ready: '#1890ff',
};

export const BUTTON_DISABLED_TIME = 500;
