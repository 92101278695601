import 'react-responsive-modal/styles.css';
import 'moment/locale/ru';

import './assets/scss/index.scss';

import { ConfigProvider } from 'antd';
import ru from 'antd/lib/locale-provider/ru_RU';
import React, { FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { Loader } from './components/loader';
import { Routes } from './routes';
import { store } from './store';

const IGaming: FC = () => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    store.checkAuthorization().then(() => setLoading(false));
  }, []);

  if (loading) return <Loader />;

  return (
    <div className="igaming">
      <ConfigProvider locale={ru}>
        <Routes />
      </ConfigProvider>
    </div>
  );
};

ReactDOM.render(<IGaming />, document.getElementById('root'));
