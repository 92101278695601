import { action, makeObservable, observable, runInAction } from 'mobx';

import { API, INotification, IUser } from '../api';
import { rest } from '../api/rest-config';
import { sessions } from '../modules/sessions';

class Store {
  @observable public user: IUser | null = null;

  @action public setUser = (user: IUser | null): void => {
    this.user = user;
  };

  @observable public notifications: INotification[] = [];
  @observable public badges: Record<string, number> = {};

  public getNotificationsWithBadges = async (): Promise<void> => {
    if (!this.user) return;

    const { badges, notifications } = await API.Notifications.getNotificationsWithBadges();

    runInAction(() => {
      this.badges = badges;
      this.notifications = notifications;
    });
  };

  public checkAuthorization = async (): Promise<void> => {
    const token = rest.getToken();

    if (token) {
      try {
        rest.setToken(token);
        const user = await API.Users.getCurrentUser();
        store.setUser(user);
        store.getNotificationsWithBadges().then();
      } catch (err) {
        if (rest.getStatus() === 401) {
          rest.setToken(null);
          store.setUser(null);
          sessions.delete(token);
        }
        console.error(err);
      }
    }
  };

  constructor() {
    setInterval(this.getNotificationsWithBadges, 30000);
    makeObservable(this);
  }
}

export const store = new Store();
