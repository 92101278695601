import { AndroidFilled, AppleFilled } from '@ant-design/icons';
import { Progress, Space, Tag, Tooltip, Typography } from 'antd';

import { AppStatusNames, IApp } from '../../api';
import { colors } from '../../constants';
import { Icon } from '../icon';

const getAndroidStatusColor = ({ isPublished, status }: IApp): string => {
  if (status === 'blocked' || status === 'deleted') {
    return colors.red;
  }

  if (status === 'active' && isPublished) {
    return colors.green;
  }

  if (status === 'active' && !isPublished) {
    return colors.red;
  }

  return colors.gray;
};

const getAppTitle = (app: IApp): string => {
  return `${app.isPublished ? 'Опубликовано' : 'Не опубликовано'}, ${AppStatusNames[app.status]}`;
};

export const App = ({
  app,
  showProgress = false,
  showCategory = true,
  showPackageId = true,
}: {
  app: IApp;
  showProgress?: boolean;
  showCategory?: boolean;
  showPackageId?: boolean;
}) => (
  <Space direction="horizontal">
    <Icon icon={app.icon} title={app.packageId || 'App'} />
    <Space size={-2} direction="vertical">
      <Space size={4}>
        <Typography.Text>{app.title}</Typography.Text>

        {app.exclusiveUser ? (
          <Tooltip title={`${app.exclusiveUser.name} (${app.exclusiveUser.email})`}>
            <Tag style={{ marginLeft: 5 }} color={colors.orange}>
              Private
            </Tag>
          </Tooltip>
        ) : null}
      </Space>

      {showPackageId && (
        <>
          {app.platform === 'android' && app.packageId ? (
            <Tooltip title={getAppTitle(app)}>
              <Typography.Link
                style={{ whiteSpace: 'nowrap' }}
                target="_blank"
                href={`https://play.google.com/store/apps/details?id=${app.packageId}`}
              >
                <Space size={3}>
                  <AndroidFilled
                    style={{
                      color: getAndroidStatusColor(app),
                    }}
                  />
                  {app.packageId}
                </Space>
              </Typography.Link>
            </Tooltip>
          ) : null}
        </>
      )}

      {app.platform === 'ios' && (
        <Typography.Text>
          <Space size={3}>
            <AppleFilled />
            <Typography.Text>{app.packageId}</Typography.Text>
          </Space>
        </Typography.Text>
      )}

      {showProgress && app.status === 'new' && (
        <Progress style={{ display: 'block', marginTop: 2 }} strokeColor="#52c41a" percent={app.progress} steps={5} />
      )}

      {showCategory && app.category ? <Typography.Text type="secondary">{app.category.name}</Typography.Text> : null}
    </Space>
  </Space>
);
