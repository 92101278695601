import { useEffect, useState } from 'react';

import { API, IStream } from '../../api';

export const useStreams = () => {
  const [streams, setStreams] = useState<IStream[]>([]);

  useEffect(() => {
    API.Streams.index({ limit: 1000 }).then(({ data }) => {
      setStreams(data);
    });
  }, []);

  return streams;
};
