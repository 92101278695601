import { Line } from '@ant-design/charts';
import { ReloadOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, DatePicker, Form, PageHeader, Row, Select, Space, Table } from 'antd';
import { runInAction } from 'mobx';
import { Observer, useLocalObservable } from 'mobx-react';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';

import { API, IGetStatsRequest, IGetStatsResponse, IStatsDataRow, TCountryCode } from '../../api';
import ResetFilters from '../../components/resetFilters';
import { useApps } from '../../hooks/apps';
import { useCountries } from '../../hooks/countries';
import { useStreams } from '../../hooks/streams';
import { useUsers } from '../../hooks/users';
import { filter } from '../../modules/filter';
import { isGranted } from '../../modules/isGranted';
import { selectFilterOption } from '../../modules/selectFilterOption';
import { toast } from '../../modules/toast';

interface IChartRecord {
  key: string | number | null;
  value: number;
  category: string;
}

interface IStatistics {
  data: IGetStatsResponse;
  loading: boolean;
  sources: string[];
  filter: IGetStatsRequest;
  chartData: IChartRecord[];
}

export const Stats = () => {
  const countries = useCountries();
  const apps = useApps();

  const users = useUsers();
  const streams = useStreams();

  const statistics = useLocalObservable<IStatistics>(() => ({
    loading: true,
    data: {} as IGetStatsResponse,
    chartData: [],
    sources: [],
    filter: filter.get<IGetStatsRequest>({
      group: 'date',
      sort: 'date',
      countryId: null,
      appId: null,
      order: 'descend',
      periodBegin: null,
      periodEnd: null,
      platform: null,
      streamId: null,
      userId: null,
      source: null,
    }),
  }));

  const getStatistics = useCallback(async () => {
    runInAction(() => {
      statistics.loading = true;
    });

    try {
      const result = await API.Stats.getStats(statistics.filter);

      const data: IChartRecord[] = [];
      if (statistics.filter.group === 'date') {
        result.data.forEach((row) => {
          const key = row.id;
          data.push({ key, value: row.installs, category: 'Установки' });
          data.push({ key, value: row.views, category: 'Просмотры' });
          data.push({ key, value: row.registrations, category: 'Регистрации' });
          data.push({ key, value: row.purchases, category: 'Покупки' });
        });
      }

      runInAction(() => {
        statistics.data = result;
        statistics.loading = false;
        statistics.chartData = data
          .sort((a, b) => (a.key! > b.key! ? 1 : -1))
          .map((row) => {
            row.key = moment(row.key).format('DD.MM.YYYY');
            return row;
          });
      });
    } catch (err) {
      toast.error(err);
    }
  }, [statistics]);

  const changeFilter = useCallback(
    (key: keyof IGetStatsRequest, value: IGetStatsRequest[keyof IGetStatsRequest]) => {
      runInAction(() => {
        statistics.filter = { ...statistics.filter, [key]: value || null };
      });

      filter.set(statistics.filter);

      getStatistics();
    },
    [statistics, getStatistics]
  );

  const changeSort = useCallback(
    (request: IGetStatsRequest) => {
      runInAction(() => {
        statistics.filter = request;
      });

      filter.set(statistics.filter);

      getStatistics();
    },
    [getStatistics, statistics]
  );

  const getSources = useCallback(() => {
    API.Stats.getStatsSources().then((sources) => {
      runInAction(() => (statistics.sources = sources));
    });
  }, [statistics]);

  useEffect(() => {
    getStatistics();
    getSources();
  }, [getStatistics, getSources]);

  return (
    <Observer>
      {() => (
        <>
          <PageHeader
            title="Статистика"
            extra={[
              <Button
                style={{ marginLeft: 60 }}
                key={0}
                icon={<ReloadOutlined />}
                onClick={getStatistics}
                type="primary"
              >
                Обновить
              </Button>,
              <ResetFilters key="reset" />,
            ]}
          />
          <Card>
            <Form
              layout="vertical"
              initialValues={{
                periodEnd: statistics.filter.periodEnd ? moment(statistics.filter.periodEnd) : '',
                periodBegin: statistics.filter.periodBegin ? moment(statistics.filter.periodBegin) : '',
                countryId: statistics.filter.countryId || '',
                platform: statistics.filter.platform || '',
                source: statistics.filter.source || '',
                group: statistics.filter.group,
                sort: statistics.filter.sort,
                userId: statistics.filter.userId ? Number(statistics.filter.userId) : '',
                appId: statistics.filter.appId ? Number(statistics.filter.appId) : '',
                streamId: statistics.filter.streamId ? Number(statistics.filter.streamId) : '',
              }}
            >
              <Row>
                <Col span={7}>
                  <Form.Item label="Группировка" name="group">
                    <Select onChange={(value: IGetStatsRequest['group']) => changeFilter('group', value)}>
                      <Select.Option value="date">Дата</Select.Option>
                      <Select.Option value="app_id">Приложение</Select.Option>
                      <Select.Option value="stream_id">Поток</Select.Option>
                      {isGranted('ROLE_ADMIN', <Select.Option value="user_id">Пользователь</Select.Option>)}
                      <Select.Option value="country_id">Страна</Select.Option>
                      <Select.Option value="platform">Платформа</Select.Option>
                      <Select.Option value="source">Источник</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col offset={1} span={5}>
                  <Form.Item label="Страна" name="countryId">
                    <Select
                      filterOption={selectFilterOption}
                      placeholder="Не важно"
                      showSearch
                      onChange={(value: TCountryCode) => changeFilter('countryId', value)}
                    >
                      <Select.Option value="">Не важно</Select.Option>
                      {countries.map(({ id, name }) => (
                        <Select.Option key={id} value={id}>
                          {`[${id.toUpperCase()}] ${name}`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={5} offset={1}>
                  <Form.Item label="Источник" name="source">
                    <Select onChange={(value: string) => changeFilter('source', value)}>
                      <Select.Option value="">Не важно</Select.Option>
                      {statistics.sources.map((source) => (
                        <Select.Option value={source} key={source}>
                          {source}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col offset={1} span={4}>
                  <Form.Item label="Платформа" name="platform">
                    <Select onChange={(value: string) => changeFilter('platform', value)}>
                      <Select.Option value="">Не важно</Select.Option>
                      <Select.Option value="android">Android</Select.Option>
                      <Select.Option value="ios">iOS</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={3}>
                  <Form.Item label="Начало периода" name="periodBegin">
                    <DatePicker
                      format="DD.MM.YYYY"
                      placeholder={moment().add(-7, 'days').format('DD.MM.YYYY')}
                      onChange={(value: any) => {
                        changeFilter('periodBegin', value ? value.format('YYYY-MM-DD') : null);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={3} offset={1}>
                  <Form.Item label="Конец периода" name="periodEnd">
                    <DatePicker
                      format="DD.MM.YYYY"
                      placeholder={moment().format('DD.MM.YYYY')}
                      onChange={(value: any) => changeFilter('periodEnd', value ? value.format('YYYY-MM-DD') : null)}
                    />
                  </Form.Item>
                </Col>

                <Col span={5} offset={1}>
                  <Form.Item label="Приложение" name="appId">
                    <Select
                      showSearch
                      filterOption={selectFilterOption}
                      onChange={(value: string) => changeFilter('appId', value)}
                    >
                      <Select.Option value="">Не важно</Select.Option>
                      {apps.map((a) => (
                        <Select.Option key={a.id} value={a.id}>
                          {a.title}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {isGranted(
                  'ROLE_ADMIN',
                  <Col span={5} offset={1}>
                    <Form.Item label="Партнер" name="userId">
                      <Select
                        showSearch
                        filterOption={selectFilterOption}
                        onChange={(value: string) => changeFilter('userId', value)}
                      >
                        <Select.Option value="">Не важно</Select.Option>
                        {users.map((user) => (
                          <Select.Option key={user.id} value={user.id}>
                            [{user.id}] {user.name} ({user.email})
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}

                <Col offset={1} span={4}>
                  <Form.Item label="Поток" name="streamId">
                    <Select
                      showSearch
                      filterOption={selectFilterOption}
                      onChange={(value: string) => changeFilter('streamId', value)}
                    >
                      <Select.Option value="">Не важно</Select.Option>

                      {streams.map((stream) => (
                        <Select.Option key={stream.id} value={stream.id}>
                          [{stream.id}] {stream.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>

          <Table
            onChange={({ current }, filters: any, sorter: any) => {
              changeSort({
                ...statistics.filter,
                sort: sorter.field === 'name' ? 'date' : sorter.field,
                order: sorter.order,
              });
            }}
            pagination={false}
            rowKey={(statistic) => statistic.key}
            dataSource={statistics.data.data}
            loading={statistics.loading}
          >
            <Table.Column
              width={250}
              sorter={statistics.filter.group === 'date'}
              defaultSortOrder={statistics.filter.sort === 'date' ? statistics.filter.order : undefined}
              dataIndex="name"
              title={
                {
                  date: 'Дата',
                  app_id: 'Приложение',
                  stream_id: 'Поток',
                  user_id: 'Арбитран',
                  country_id: 'Страна',
                  platform: 'Платформа',
                  source: 'Источник',
                }[statistics.filter.group]
              }
              render={(name: string, item: IStatsDataRow) => (
                <Space>
                  {item.icon ? <Avatar shape="square" size={24} src={item.icon.url} /> : null}
                  <span>
                    {['app_id', 'stream_id'].includes(statistics.filter.group) ? `(${item.id})` : null}
                    {statistics.filter.group === 'date' ? moment(name).format('DD.MM.YYYY') : name}
                  </span>
                </Space>
              )}
            />
            <Table.Column
              defaultSortOrder={statistics.filter.sort === 'installs' ? statistics.filter.order : undefined}
              sorter
              width={250}
              title="Установок"
              dataIndex="installs"
              align="right"
            />
            <Table.Column
              defaultSortOrder={statistics.filter.sort === 'views' ? statistics.filter.order : undefined}
              sorter
              width={250}
              title="Просмотров"
              dataIndex="views"
              align="right"
            />
            <Table.Column
              defaultSortOrder={statistics.filter.sort === 'registrations' ? statistics.filter.order : undefined}
              sorter
              width={250}
              title="Регистраций"
              dataIndex="registrations"
              align="right"
            />
            <Table.Column
              defaultSortOrder={statistics.filter.sort === 'purchases' ? statistics.filter.order : undefined}
              sorter
              width={250}
              title="Покупок"
              dataIndex="purchases"
              align="right"
            />
          </Table>
          {statistics.chartData.length > 0 && (
            <Card style={{ marginTop: 20 }} title="График по дням">
              <Line data={statistics.chartData} xField="key" yField="value" seriesField="category" />
            </Card>
          )}
        </>
      )}
    </Observer>
  );
};
