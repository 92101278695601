import './index.scss';

import {
  AppstoreOutlined,
  BarChartOutlined,
  BranchesOutlined,
  ClusterOutlined,
  DashboardOutlined,
  DotChartOutlined,
  FireOutlined,
  GroupOutlined,
  NodeIndexOutlined,
  NotificationOutlined,
  PicLeftOutlined,
  PictureOutlined,
  SettingOutlined,
  ShareAltOutlined,
  ShoppingCartOutlined,
  SwapOutlined,
  UploadOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { Layout } from 'antd';
import block from 'bem-cn';
import { Observer } from 'mobx-react';
import React from 'react';

import { ISidebarLink } from '../../interfaces';
import { isGranted } from '../../modules/isGranted';
import { Logo } from '../logo';
import { Menu } from '../menu';

const { Sider } = Layout;

const b = block('sidebar');

const mainMenu: ISidebarLink[] = [
  {
    title: 'Статистика',
    icon: <BarChartOutlined />,
    path: '/',
    role: ['ROLE_ADMIN', 'ROLE_PARTNER', 'ROLE_MANAGER'],
    exact: true,
  },
  {
    title: 'Мои приложения',
    icon: <AppstoreOutlined />,
    path: '/apps',
    role: ['ROLE_PARTNER'],
  },
  {
    title: 'Потоки',
    icon: <SwapOutlined />,
    path: '/streams',
    role: ['ROLE_ADMIN', 'ROLE_PARTNER', 'ROLE_MANAGER'],
    badge: 'streams',
  },
  {
    title: 'Пуши',
    icon: <NotificationOutlined />,
    path: '/pushes',
    role: ['ROLE_PARTNER', 'ROLE_ADMIN', 'ROLE_MANAGER'],
  },
  {
    title: 'Системные пуши',
    icon: <NotificationOutlined />,
    path: '/push-templates',
    role: ['ROLE_ADMIN', 'ROLE_MANAGER'],
  },
  {
    title: 'Системные пуши',
    icon: <NotificationOutlined />,
    path: '/push-system',
    role: ['ROLE_PARTNER'],
  },
  {
    title: 'Статистика пушей',
    icon: <BarChartOutlined />,
    path: '/push-statistics',
    role: ['ROLE_PARTNER', 'ROLE_ADMIN', 'ROLE_MANAGER'],
  },
  {
    title: 'Расшарка',
    icon: <ShareAltOutlined />,
    path: '/share',
    role: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    badge: 'shares',
  },
  {
    title: 'Задачи',
    icon: <PicLeftOutlined />,
    path: '/tasks',
    role: ['ROLE_EMPLOYEE'],
    badge: 'tasks',
  },
  {
    title: 'Пользователи',
    icon: <UsergroupAddOutlined />,
    path: '/users',
    role: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    badge: 'users',
    children: [
      {
        title: 'Список',
        icon: <UsergroupAddOutlined />,
        path: '/users',
        exact: true,
        role: ['ROLE_ADMIN', 'ROLE_MANAGER'],
        badge: 'users',
      },
      {
        title: 'Группы',
        icon: <GroupOutlined />,
        path: '/users/groups',
        role: ['ROLE_ADMIN', 'ROLE_MANAGER'],
      },
    ],
  },

  {
    title: 'Приложения',
    icon: <AppstoreOutlined />,
    path: '/apps/dashboard',
    role: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    badge: 'apps',
    children: [
      {
        title: 'Сводная страница',
        icon: <DashboardOutlined />,
        path: '/apps/dashboard',
        role: ['ROLE_ADMIN', 'ROLE_MANAGER'],
        exact: true,
      },
      {
        title: 'Список приложений',
        icon: <AppstoreOutlined />,
        path: '/apps',
        role: ['ROLE_ADMIN', 'ROLE_MANAGER'],
        badge: 'apps',
        exact: true,
      },
    ],
    exact: false,
  },
  {
    title: 'Отчеты',
    icon: <DotChartOutlined />,
    path: '/reports',
    role: ['ROLE_ADMIN', 'ROLE_EMPLOYEE'],
    children: [
      {
        title: 'Заливки в стор',
        icon: <ShoppingCartOutlined />,
        path: '/reports/apps/uploads',
        role: ['ROLE_ADMIN'],
        exact: true,
      },
      {
        title: 'План загрузок',
        icon: <UploadOutlined />,
        path: '/reports/apps/schedule',
        role: ['ROLE_ADMIN', 'ROLE_EMPLOYEE'],
        exact: true,
      },
    ],
    exact: false,
  },
  {
    title: 'Задачи',
    icon: <PicLeftOutlined />,
    path: '/tasks',
    role: ['ROLE_ADMIN'],
    badge: 'tasks',
  },
  {
    title: 'Поля',
    icon: <GroupOutlined />,
    path: '/fields',
    role: ['ROLE_ADMIN'],
  },
  {
    title: 'Шаги',
    icon: <BranchesOutlined />,
    path: '/steps',
    role: ['ROLE_ADMIN'],
  },
  {
    title: 'Флоу',
    icon: <NodeIndexOutlined />,
    path: '/flows',
    role: ['ROLE_ADMIN'],
  },
  {
    title: 'Процессы',
    icon: <FireOutlined />,
    path: '/processes',
    role: ['ROLE_ADMIN'],
  },

  {
    title: 'Прочее',
    icon: <SettingOutlined />,
    path: '/utils',
    role: ['ROLE_ADMIN', 'ROLE_EMPLOYEE'],
    badge: 'apps',
    children: [
      {
        title: 'Игры',
        icon: <PictureOutlined />,
        path: '/utils/games',
        role: ['ROLE_ADMIN', 'ROLE_EMPLOYEE'],
      },
      {
        title: 'Прокси',
        icon: <ClusterOutlined />,
        path: '/utils/proxies',
        role: ['ROLE_ADMIN'],
        badge: 'proxies',
      },
    ],
    exact: false,
  },

  {
    title: 'Профиль',
    icon: <UserOutlined />,
    path: `/profile`,
    role: ['ROLE_PARTNER', 'ROLE_ADMIN', 'ROLE_EMPLOYEE', 'ROLE_MANAGER'],
  },
];

export const Sidebar = () => {
  return (
    <Observer>
      {() => (
        <Sider className={b()}>
          <Logo mode="light" />
          <Menu items={mainMenu.filter((link) => isGranted(link.role))} />
          {/*{isGranted('ROLE_ADMIN', <Menu items={factoryMenu} />)}*/}
        </Sider>
      )}
    </Observer>
  );
};
