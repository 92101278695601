import { useEffect, useState } from 'react';

import { API, IApp, IGetSimpleAppsRequest } from '../../api';

const defaultPayload: IGetSimpleAppsRequest = {};

export const useApps = (payload = defaultPayload) => {
  const [apps, setApps] = useState<IApp[]>([]);

  useEffect(() => {
    API.Apps.getSimpleList(payload).then((data) => {
      setApps(data);
    });
  }, [payload]);

  return apps;
};
