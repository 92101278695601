export const filter = {
  get<T>(filter: T): T {
    // v3 (localstorage)
    const stored = JSON.parse(localStorage[window.location.pathname] || '{}');
    return { ...filter, ...stored };

    // v2 (json-url)
    // const queryParams = new URLSearchParams(window.location.search);
    // const params = JSON.parse(queryParams.get('filters') || '{}');
    // return { ...filter, ...params };

    //v1 (vadim)
    // const getType = (value: string) => {
    //   if (value === 'undefined') return undefined;
    //   if (value === 'null') return null;
    //   if (value === 'true') return true;
    //   if (value === 'false') return false;
    //
    //   if (value.includes('[')) {
    //     value = value.replace('[', '').replace(']', '');
    //     return value.split(',');
    //   }
    //
    //   return value;
    // };
    //
    // let url = new URL(window.location.href);
    // let params = new URLSearchParams(url.search.slice(1));
    //
    // const f: any = {};
    //
    // for (let pair of params.entries()) {
    //   f[pair[0]] = getType(pair[1]);
    // }
    //
    // return { ...filter, ...f };
  },
  set<T>(filter: T) {
    // v3
    localStorage[window.location.pathname] = JSON.stringify(filter);

    // v2
    // window.history.replaceState(null, '', '?filters=' + JSON.stringify(filter));

    // v1 (vadim)
    // const queryParams = new URLSearchParams(window.location.search);
    // Object.entries(filter).forEach(([key, value]) => {
    //   if (Array.isArray(value)) {
    //     value = `[${value.join(',')}]`;
    //   }
    //
    //   if (value || value === null || value === false) {
    //     queryParams.set(key, value);
    //   } else {
    //     queryParams.delete(key);
    //   }
    // });
    // window.history.replaceState(null, '', '?' + queryParams.toString());
  },
};
