import './index.scss';

import { Spin } from 'antd';
import block from 'bem-cn';
import { useEffect, useState } from 'react';

const b = block('loader');

export const Loader = ({ delay = 0 }: { delay?: number }) => {
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    let timeout: any = null;

    if (delay) {
      timeout = setTimeout(() => setVisible(true), delay);
    } else {
      setVisible(true);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [delay]);

  return (
    <div style={{ opacity: visible ? 1 : 0 }} className={b()}>
      <Spin />
    </div>
  );
};
