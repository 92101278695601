import { CheckOutlined, LockOutlined, PlusOutlined, ShareAltOutlined } from '@ant-design/icons';
import { Button, PageHeader, Space, Table, Tag } from 'antd';
import { makeObservable, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { PureComponent } from 'react';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import {
  API,
  AppStatusNames,
  EnabledSourceNames,
  IApp,
  ICategory,
  IGetAppsRequest,
  IPagedData,
  TAppStatus,
  TEnabledSource,
} from '../../api';
import { App } from '../../components/app';
import ResetFilters from '../../components/resetFilters';
import { openCreateStreamModal } from '../../modals/createStream';
import { toast } from '../../modules/toast';

@observer
export class MyApps extends PureComponent<RouteComponentProps> {
  constructor(p: RouteComponentProps) {
    super(p);
    makeObservable(this);
  }

  @observable private loading: boolean = true;
  @observable private response: IPagedData<IApp> = {
    page: 1,
    pages: 0,
    limit: 20,
    data: [],
    count: 0,
  };
  @observable private request: IGetAppsRequest = {
    status: ['active'],
    page: 1,
    limit: 20,
  };

  @observable categories: ICategory[] = [];

  private getMyApps = async () => {
    runInAction(() => (this.loading = true));
    const result = await API.My.apps(this.request);
    runInAction(() => {
      this.response = result;
      this.loading = false;
    });
  };

  createStream = async (appId: number): Promise<void> => {
    const stream = await openCreateStreamModal(appId);

    if (stream) {
      this.props.history.push(`/streams/manage/${stream.id}`);
    }
  };

  public componentDidMount = async () => {
    await this.getMyApps();
    API.Categories.index()
      .then((categories) => runInAction(() => (this.categories = categories)))
      .catch(toast.error);
  };

  render() {
    return (
      <React.Fragment>
        <PageHeader title="Мои приложения" extra={[<ResetFilters key="reset" />]} />
        <Table
          pagination={{
            pageSize: this.response.limit,
            current: this.response.page,
            total: this.response.count ?? undefined,
          }}
          rowKey={(app) => app.id}
          dataSource={this.response.data}
          loading={this.loading}
          onChange={({ current }, filters) => {
            runInAction(() => {
              this.request = {
                ...this.request,
                page: current,
                categoryId: (filters.category as number[]) || undefined,
                source: (filters.source as TEnabledSource[]) || undefined,
                status: (filters.status as TAppStatus[]) || undefined,
              };
            });
            this.getMyApps();
          }}
        >
          <Table.Column width={50} title="ID" dataIndex="id" />

          <Table.Column
            title="Приложение"
            render={(item: IApp) => {
              return <App app={item} />;
            }}
          />

          <Table.Column
            title="Категория"
            dataIndex="category"
            render={(category) => category.name}
            filters={this.categories.map(({ id, name }) => ({ value: id, text: name }))}
            defaultFilteredValue={this.request.categoryId as number[]}
          />

          <Table.Column
            filters={Object.entries(EnabledSourceNames).map(([id, name]) => ({ value: id, text: name }))}
            defaultFilteredValue={this.request.source as string[]}
            title="Источники"
            dataIndex="source"
            render={(_, app: IApp) =>
              Object.entries(EnabledSourceNames).map(([id, name]) => (
                <div
                  className={`text-small ${
                    app.sources?.includes(id as TEnabledSource) ? 'color-green' : 'color-muted'
                  }`}
                  key={id}
                >
                  <Space size={3}>
                    {app.sources?.includes(id as TEnabledSource) ? <CheckOutlined /> : <LockOutlined />}
                    <span>{name}</span>
                  </Space>
                </div>
              ))
            }
          />

          <Table.Column
            defaultFilteredValue={this.request.status as TAppStatus[]}
            filters={Object.entries(AppStatusNames).map(([id, name]) => ({ value: id, text: name }))}
            title="Статус"
            dataIndex="status"
            render={(status: TAppStatus) => (
              <Tag
                color={
                  {
                    active: '#52c41a',
                    new: '#faad14',
                    blocked: '#ff4d4f',
                    deleted: 'rgba(0,0,0,.25)',
                    ready: '#1890ff',
                  }[status]
                }
              >
                {AppStatusNames[status]}
              </Tag>
            )}
          />

          <Table.Column
            width={50}
            dataIndex="id"
            render={(id: number, app: IApp) => (
              <Space style={{ float: 'right' }}>
                {app.status === 'active' && (
                  <Button
                    onClick={() => this.createStream(id)}
                    title="Создать поток"
                    type="primary"
                    size="small"
                    icon={<PlusOutlined />}
                  >
                    Поток
                  </Button>
                )}

                <Button
                  onClick={() => this.props.history.push(`/apps/share/${id}`)}
                  title="Расшарка"
                  type="primary"
                  size="small"
                  icon={<ShareAltOutlined />}
                >
                  Расшарка
                </Button>

                {/*<Button*/}
                {/*  onClick={() => this.props.history.push(`/apps/pushes/${id}`)}*/}
                {/*  size="small"*/}
                {/*  type="primary"*/}
                {/*  icon={<SettingOutlined />}*/}
                {/*>*/}
                {/*  Пуши*/}
                {/*</Button>*/}
              </Space>
            )}
          />
        </Table>
      </React.Fragment>
    );
  }
}
