import { useEffect, useState } from 'react';

import { API, IApp } from '../../api';

export const useMyApps = () => {
  const [apps, setApps] = useState<IApp[]>([]);

  useEffect(() => {
    API.My.apps({ status: 'active', limit: 1000 }).then((data) => {
      setApps(data.data);
    });
  }, []);

  return apps;
};
