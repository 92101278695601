import { RedoOutlined, WarningOutlined } from '@ant-design/icons';
import { Alert, Button } from 'antd';
import React, { FC } from 'react';

import { store } from '../../store';

export const InterfaceUpdatingMessage: FC = () => {
  if (store.badges.upgrade_required) {
    return (
      <Alert
        showIcon
        icon={<WarningOutlined />}
        message="Интерфейс устарел. Обновите страницу с очисткой кэша"
        type="error"
        style={{ borderRadius: 0, top: '-10px' }}
        action={
          <Button onClick={() => window.location.reload()} icon={<RedoOutlined />} size="small" type="primary" danger>
            Обновить
          </Button>
        }
      />
    );
  }

  return null;
};
