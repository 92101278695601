import './index.scss';

import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, Form, Input, Layout, Row, Space, Typography } from 'antd';
import block from 'bem-cn';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { API, ILoginRequest, UserRoleNames } from '../../api';
import { rest } from '../../api/rest-config';
import { Logo } from '../../components/logo';
import { RoleColor } from '../../constants';
import { sessions } from '../../modules/sessions';
import { toast } from '../../modules/toast';
import { store } from '../../store';

const b = block('screenLogin');

@observer
export class Login extends PureComponent<RouteComponentProps> {
  constructor(props: RouteComponentProps) {
    super(props);
    makeObservable(this);
  }

  private sessions = sessions.get();

  @observable loading: boolean = false;

  @action login = async ({ email, password }: ILoginRequest): Promise<void> => {
    try {
      runInAction(() => {
        this.loading = true;
      });

      const session = await API.Auth.login({ email, password });

      rest.setToken(session.token);
      store.setUser(session.user);

      sessions.set({
        id: session.user.id,
        token: session.token,
        role: session.user.role,
        name: session.user.name || session.user.email,
        avatar: session.user.avatar ? session.user.avatar.url : null,
      });

      await store.getNotificationsWithBadges();

      this.props.history.replace('/');
    } catch (err) {
      toast.error(err);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  render() {
    return (
      <Layout className={b()}>
        <Card title="Вход" extra={<Logo mode="dark" />}>
          <Form onFinish={this.login}>
            <Form.Item
              name="email"
              rules={[
                {
                  type: 'email',
                  required: true,
                  message: 'Пожалуйста, введите вашу почту',
                },
              ]}
            >
              <Input size="large" placeholder="Почта" prefix={<UserOutlined />} />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Пожалуйста, введите пароль',
                },
              ]}
            >
              <Input.Password size="large" placeholder="Пароль" prefix={<LockOutlined />} />
            </Form.Item>

            <Row align="middle" justify="space-between">
              <Col span={12}>
                <Form.Item noStyle>
                  <Button size="large" type="primary" htmlType="submit" loading={this.loading}>
                    Войти
                  </Button>
                </Form.Item>
              </Col>
              <Typography.Link onClick={() => this.props.history.push('/register')}>Регистрация</Typography.Link>
            </Row>
          </Form>

          {this.sessions.length ? (
            <div className={b('sessions')}>
              {this.sessions.map((session, index) => (
                <div onClick={() => sessions.switch(session.token)} key={index} className={b('session')}>
                  <Avatar className={b('avatar').toString()} src={session.avatar}>
                    {session.name[0]}
                  </Avatar>
                  <Space size={-3} direction="vertical">
                    <Typography.Text className={b('sessionName').toString()}>{session.name}</Typography.Text>
                    <Typography.Text style={{ color: RoleColor[session.role] }} className={b('sessionRole').toString()}>
                      {UserRoleNames[session.role]}
                    </Typography.Text>
                  </Space>
                </div>
              ))}
            </div>
          ) : null}
        </Card>
      </Layout>
    );
  }
}
