import { TUserRole } from '../../api';

interface ISession {
  id: number;
  avatar: string | null;
  name: string;
  role: TUserRole;
  token: string;
}

class Sessions {
  private sessions: ISession[] = [];

  constructor() {
    this.getSessionsFromStorage();
  }

  private getSessionsFromStorage(): void {
    const sessionsString = window.localStorage.getItem('sessions');

    if (sessionsString) {
      this.sessions = JSON.parse(sessionsString);
    } else {
      this.sessions = [];
    }
  }

  private setSessionsInStorage = (): void => {
    window.localStorage.setItem('sessions', JSON.stringify(this.sessions));
  };

  public get = (): ISession[] => {
    return this.sessions;
  };

  public set = (session: ISession): void => {
    const candidate = this.sessions.find(({ id }) => id === session.id);

    if (candidate) {
      this.sessions = this.sessions.filter(({ id }) => session.id !== id);
    }

    this.sessions = [session, ...this.sessions];
    this.setSessionsInStorage();
  };

  public delete = (token: string): void => {
    this.sessions = this.sessions.filter((session) => session.token !== token);
    this.setSessionsInStorage();
  };

  public switch = (token: string): void => {
    this.sessions = this.sessions.sort((a) => (a.token === token ? -1 : 1));

    this.setSessionsInStorage();

    window.localStorage.setItem('token', token);
    window.location.href = '/';
  };
}

export const sessions = new Sessions();
