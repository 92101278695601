import './index.scss';

import { Avatar, Button, Dropdown, Menu, Space, Typography } from 'antd';
import block from 'bem-cn';
import React from 'react';

import { API, IUser, UserRoleNames } from '../../api';
import { rest } from '../../api/rest-config';
import { RoleColor } from '../../constants';
import { sessions } from '../../modules/sessions';
import { store } from '../../store';
import { Icon } from '../icon';

const b = block('sessions');

const logout = () => {
  API.Auth.logout().finally(() => {
    const token = window.localStorage.getItem('token');

    if (token) {
      sessions.delete(token);
    }

    store.setUser(null);
    rest.setToken(null);
  });
};

const newSession = () => {
  window.localStorage.removeItem('token');
  store.setUser(null);
};

export const Sessions = ({ user }: { user: IUser }) => {
  const sessionsList = sessions.get();

  if (sessionsList.length)
    return (
      <div className={b()}>
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu className={b('list').toString()}>
              {sessionsList.length ? (
                <>
                  {sessionsList.map((session) => (
                    <Menu.Item
                      onClick={() => {
                        sessions.switch(session.token);
                      }}
                      isSelected={false}
                      key={session.token}
                    >
                      <Space className={b('item').toString()}>
                        <Avatar src={session.avatar || undefined}>
                          {session.name ? session.name[0].toUpperCase() : ''}
                        </Avatar>

                        <Space direction="vertical" size={-5}>
                          <Typography.Text>{session.name}</Typography.Text>
                          <Typography.Text
                            style={{
                              color: RoleColor[session.role],
                              fontSize: 12,
                            }}
                          >
                            {UserRoleNames[session.role]}
                          </Typography.Text>
                        </Space>

                        {/*{session.token !== window.localStorage.token && (*/}
                        {/*  <Button*/}
                        {/*    onClick={(e) => {*/}
                        {/*      e.stopPropagation();*/}
                        {/*      deleteSession(session.token);*/}
                        {/*    }}*/}
                        {/*    icon={<PoweroffOutlined />}*/}
                        {/*    size="small"*/}
                        {/*  />*/}
                        {/*)}*/}
                      </Space>
                    </Menu.Item>
                  ))}

                  <div className={b('footer')}>
                    <Button onClick={newSession} className={b('link').toString()} type="link">
                      Добавить аккаунт
                    </Button>

                    <Button danger onClick={logout} className={b('link').toString()} type="link">
                      Выход
                    </Button>
                  </div>
                </>
              ) : (
                <Menu.Item>
                  <Typography.Text type="secondary">Нет сессий</Typography.Text>
                </Menu.Item>
              )}
            </Menu>
          }
          placement="bottomRight"
        >
          <Space>
            {user ? <span className={b('email')}>{user.name || user.email}</span> : null}
            {user ? <Icon icon={user.avatar} title={user.name || user.email} /> : null}
          </Space>
        </Dropdown>
      </div>
    );

  return null;
};
