import './index.scss';

import block from 'bem-cn';
import React, { FC } from 'react';

import logo from '../../assets/images/logo.svg';

//import { getRandomInt } from '../../modules/getRandomInt';

const b = block('logo');

//const logos = ['🌽', '🍌', '🍆', '🥒', '🌶', '🥕', '🥖', '🦴', '🌭'];
// const number = getRandomInt(0, logos.length - 1);

interface ILogo {
  mode: 'dark' | 'light';
}

export const Logo: FC<ILogo> = ({ mode = 'light' }: ILogo) => (
  <div className={b({ mode })}>
    <img src={logo} width={50} height={50} alt="iGaming Apps" />
    {/*<span className={b('emoji', { number: number.toString() })}>{logos[number]}</span>*/}
    <span translate="no">Apps</span>
  </div>
);
