export const useNamingMacros = () => {
  const utm: Record<string, string> = {
    '{lang}': 'язык пользователя',
    '{uid}': 'уникальный ID инсталла',
    '{platform}': 'платформа',
    '{campaign}': 'название кампании',
    '{adset}': 'название группы объявлений',
    '{geo}': 'страна пользователя',
    '{source}': 'источник',
    '{stream}': 'ID потока',
    '{ip}': 'IP адрес',
    '{ad}': 'название объявления',
  };

  return utm;
};
