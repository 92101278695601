import { Avatar } from 'antd';

import { IUpload } from '../../api';

interface IIcon {
  icon: IUpload | null;
  title?: string;
  size?: number;
}

export const Icon = ({ icon, title = 'Unknown', size }: IIcon) => (
  <Avatar size={size} className="icon" style={{ backgroundColor: '#f0f2f5' }} src={icon?.url}>
    {title ? title[0].toUpperCase() : null}
  </Avatar>
);
