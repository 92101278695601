export const selectFilterOption = (input: string, option: any): boolean => {
  input = prepare(input);
  if (input === prepare(option.value)) return true;
  if (prepare(option.value).includes(input)) return true;
  if (typeof option.children === 'string' && prepare(option.children).includes(input)) return true;
  if (option.children instanceof Array) {
    for (let i of option.children) {
      if (i !== null && prepare(i).includes(input)) return true;
    }
  }
  return false;
};

const prepare = (value: any): string => {
  return value.toString().toLowerCase().trim();
};
