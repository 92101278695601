import { notification } from 'antd';

export const toast = {
  success: (body: string, title: string = '') => {
    notification.open({
      type: 'success',
      message: title,
      description: body,
    });
  },
  error: (body: string, title: string = 'Ошибка') => {
    notification.open({
      type: 'error',
      message: title,
      description: body,
    });
  },
  warning: (body: string, title: string = '') => {
    notification.open({
      type: 'warning',
      message: title,
      description: body,
    });
  },
};
