import { useEffect, useState } from 'react';

import { API, IGetUsersRequest, IUser } from '../../api';
import { isGranted } from '../../modules/isGranted';

const defaultPayload: IGetUsersRequest = { role: 'ROLE_PARTNER', limit: 1000, status: ['active'] };

export const useUsers = (payload: IGetUsersRequest = defaultPayload) => {
  const [users, setUsers] = useState<IUser[]>([]);

  useEffect(() => {
    if (isGranted(['ROLE_MANAGER', 'ROLE_ADMIN'])) {
      API.Users.getUsers(payload).then(({ data }) => {
        setUsers(data);
      });
    }
  }, [payload]);

  return users;
};
