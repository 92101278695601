import './index.scss';

import { Layout, Space, Tag, Typography } from 'antd';
import telegram from 'assets/images/telegram.png';
import block from 'bem-cn';
import React from 'react';

import { isGranted } from '../../modules/isGranted';
import { store } from '../../store';
import { Notifications } from '../notifications';
import { Sessions } from '../sessions';

const b = block('header');

export const Header = () => {
  const { user } = store;

  return (
    <Layout.Header className={b().toString()}>
      <Space size="large">
        {isGranted(
          ['ROLE_PARTNER', 'ROLE_ADMIN'],
          <Typography.Link style={{ marginRight: 30 }} href="https://t.me/igaming_space_bot" target="_blank">
            <img width={20} height={20} className={b('telegram')} src={telegram} alt="telegram" />
            Информация по бану приложений
          </Typography.Link>
        )}

        {isGranted('ROLE_ADMIN', <Tag color="#87d068">Администратор</Tag>)}

        <Notifications />

        {user ? <Sessions user={user} /> : null}
      </Space>
    </Layout.Header>
  );
};
