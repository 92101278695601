import { rest, endpoint } from './rest-config';

if (rest.debug) console.info('REST Endpoint', endpoint);

export type TDateTime = string;

export type TDateTimeZone = string;

export type TIdentifier = string | number;

export type TLangCode = string;

export type TSortOrder = 'ascend' | 'descend';

export type TUserRole = 'ROLE_ADMIN' | 'ROLE_EMPLOYEE' | 'ROLE_MANAGER' | 'ROLE_PARTNER';

export const UserRoleNames: Record<TUserRole, string> = {
  ROLE_ADMIN: 'Администратор',
  ROLE_EMPLOYEE: 'Сотрудник',
  ROLE_MANAGER: 'Менеджер',
  ROLE_PARTNER: 'Арбитражник',
};

export type TPlatform = 'ios' | 'android' | 'unknown';

export type TEnabledSource = 'facebook' | 'google' | 'other';

export const EnabledSourceNames: Record<TEnabledSource, string> = {
  facebook: 'Facebook',
  google: 'Google Ads',
  other: 'Прочее',
};

export type TAppStatus = 'new' | 'ready' | 'active' | 'blocked' | 'deleted';

export const AppStatusNames: Record<TAppStatus, string> = {
  new: 'Разработка',
  ready: 'Готово',
  active: 'Активно',
  blocked: 'Заблокировано',
  deleted: 'Удалено',
};

export type TAppField = 'name' | 'icon' | 'packageId' | 'facebookAppId' | 'firebaseConfig' | 'oneLink' | 'isUploaded';

export const AppFieldNames: Record<TAppField, string> = {
  name: 'Название приложения',
  icon: 'Иконка',
  packageId: 'ID пакета',
  facebookAppId: 'Facebook App ID',
  firebaseConfig: 'Firebase JSON AdminSdk',
  oneLink: 'AppsFlyer OneLink',
  isUploaded: 'Залито в стор',
};

export type TFieldType = 'string' | 'text' | 'select' | 'checkbox' | 'attachment';

export const FieldTypeNames: Record<TFieldType, string> = {
  string: 'Строка',
  text: 'Текст',
  select: 'Селектор',
  checkbox: 'Чекбокс',
  attachment: 'Файл',
};

export type TTaskStatus = 'new' | 'working' | 'done';

export const TaskStatusNames: Record<TTaskStatus, string> = { new: 'Новая', working: 'В работе', done: 'Готова' };

export type TAutomationScript =
  | 'PackageIdGeneration'
  | 'LandingGeneration'
  | 'RandomSiteListGeneration'
  | 'PublishChecker'
  | 'DomainParker'
  | 'FacebookAppAccepter'
  | 'AddAppToOneSignal'
  | 'BuyProxy'
  | 'WaitFor';

export const AutomationScriptNames: Record<TAutomationScript, string> = {
  PackageIdGeneration: 'Генератор ID пакета',
  LandingGeneration: 'Генератор ленда',
  RandomSiteListGeneration: 'Генератор списка рандомных сайтов',
  PublishChecker: 'Чекер публикации в сторе',
  DomainParker: 'Парковщик доменов',
  FacebookAppAccepter: 'Приниматель приложений в Facebook Bot',
  AddAppToOneSignal: 'Добавление приложения в OneSignal',
  BuyProxy: 'Покупка прокси',
  WaitFor: 'Задержка 48 часов',
};

export type TCountryCode = string;

export type TDate = string;

export type TTime = string;

export type TShortTime = string;

export type TDayOfWeek = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';

export type TShareStatus = 'new' | 'auto' | 'manual' | 'done' | 'reject' | 'error';

export type TShareType = 'facebook' | 'google' | 'appsflyer' | 'tiktok';

export const ShareTypeNames: Record<TShareType, string> = {
  facebook: 'Facebook',
  google: 'Google Ads',
  appsflyer: 'AppsFlyer (InApp)',
  tiktok: 'AppsFlyer (TikTok)',
};

export type TUserStatus = 'pending' | 'active' | 'blocked';

export const UserStatusNames: Record<TUserStatus, string> = {
  pending: 'На рассмотрении',
  active: 'Активен',
  blocked: 'Заблокирован',
};

export type TBadge = 'users' | 'shares' | 'apps' | 'streams' | 'tasks' | 'proxies' | 'upgrade_required';

export type TPushAutomationType = 'time' | 'event';

export const PushAutomationTypeNames: Record<TPushAutomationType, string> = { time: 'По времени', event: 'По событию' };

export type TPushAutomationEvent = 'no_registration' | 'no_purchase' | 'after_registration' | 'after_purchase';

export const PushAutomationEventNames: Record<TPushAutomationEvent, string> = {
  no_registration: 'Нет регистрации после инсталла',
  no_purchase: 'Нет покупок после регистрации',
  after_registration: 'После регистрации',
  after_purchase: 'После покупки',
};

export type TTimeFactor = 'minutes' | 'hours' | 'days';

export type TFlowProcessStatus = 'active' | 'paused' | 'finished';

export const FlowProcessStatusNames: Record<TFlowProcessStatus, string> = {
  active: 'В работе',
  paused: 'На паузе',
  finished: 'Завершен',
};

export interface IPagedData<T> {
  page: number;
  limit: number;
  count: number | null;
  pages: number | null;
  data: Array<T>;
}

export interface IFlow {
  id: number;
  name: string;
  steps?: IStep[];
}

export interface IStat {}

export interface ILang {
  id: TLangCode;
  name: string;
  isActive?: boolean;
}

export interface IPush {
  id: number;
  user?: IUser | null;
  apps: IApp[];
  categories: ICategory[];
  streams: IStream[];
  name: string;
  icon?: IUpload | null;
  image: IUpload | null;
  title: string;
  body: string;
  translations: IPushTranslation[];
}

export interface IAppField {
  id: number;
  field: IField;
  lang: ILang | null;
  value: any | null;
  attachment: IUpload | null;
}

export interface IPushStat {}

export interface IDevice {
  id: string;
  app?: IApp;
  country?: ICountry;
  stream?: IStream | null;
  createdAt: TDateTime | null;
  accessedAt: TDateTime | null;
  registeredAt: TDateTime | null;
  purchasedAt: TDateTime | null;
  source: string | null;
  lang: TLangCode | null;
  platform: string;
  url: string | null;
  afId: string | null;
  ip: string | null;
}

export interface IShare {
  id: number;
  user: IUser;
  app: IApp;
  type: TShareType;
  account: string;
  status: TShareStatus;
  comment: string | null;
  createdAt: TDateTime;
  updatedAt: TDateTime;
}

export interface ITag {
  id: number;
  name: string;
}

export interface IPushAutomation {
  id: number;
  type: TPushAutomationType;
  time: string | null;
  days: TDayOfWeek[];
  event: TPushAutomationEvent | null;
  eventTime: number | null;
  eventTimeFactor: TTimeFactor | null;
  isActive: boolean;
}

export interface IAppHistoryRecord {
  id: number;
  message: string;
  comment: string | null;
  createdAt: TDateTime;
}

export interface IUserGroup {
  id: number;
  name: string;
  users?: IUser[];
}

export interface IGame {
  id: number;
  name: string;
  preview: IUpload;
  zip?: IUpload;
  config?: {};
  isActive: boolean;
}

export interface ISession {
  token: string;
  user: IUser;
}

export interface ISms {
  id: number;
  sender: string;
  receiver: string;
  message: string;
  createdAt: TDateTime;
}

export interface IProject {
  id: string;
  hash: string;
  game?: IGame;
  author?: IUser;
  app?: IApp | null;
  name: string | null;
  createdAt?: TDateTime;
  updatedAt?: TDateTime;
  previewURL: string;
  variables?: Record<string, any>;
  jsonConfig?: [];
}

export interface IFieldGroup {
  id: number;
  name: string;
  fields?: IField[];
}

export interface IUploadLock {}

export interface IFlowProcess {
  id: number;
  flow?: IFlow;
  app?: IApp;
  progress: number;
  status: TFlowProcessStatus;
  startedAt: TDateTime;
  finishedAt: TDateTime | null;
  updatedAt: TDateTime;
}

export interface IApp {
  id: number;
  name: string | null;
  title: string;
  packageId: string | null;
  version: string | null;
  langs?: ILang[];
  progress: number;
  status: TAppStatus;
  platform: TPlatform;
  isPublished: boolean;
  description?: string | null;
  icon: IUpload | null;
  firebaseConfig?: IUpload | null;
  category?: ICategory;
  exclusiveUser?: IUser | null;
  users?: IUser[];
  usersCount?: number;
  afCounter?: number;
  facebookAppId?: number | null;
  facebookSharesCount?: number;
  oneLink?: string | null;
  tags?: ITag[];
  sources?: TEnabledSource[] | null;
  scheduledAt?: TDateTime | null;
  isUploaded: boolean;
}

export interface IUpload {
  id: string;
  extra?: IUploadExtra;
  url: string;
  downloadUrl?: string;
}

export interface ICategory {
  id: number;
  name: string;
}

export interface IUser {
  id: number;
  email: string;
  name: string | null;
  role: TUserRole;
  avatar: IUpload | null;
  steps?: IStep[];
  apps?: IApp[];
  botId: number | null;
  status: TUserStatus;
  comment?: string | null;
  contactInfo?: string | null;
  userGroups?: IUserGroup[];
}

export interface IField {
  id: number;
  key: string;
  name: string;
  description?: string | null;
  type?: TFieldType;
  isTranslatable?: boolean;
  appField?: TAppField | null;
  config?: Record<string, any>;
  fieldGroup: IFieldGroup | null;
  isClonable?: boolean;
  isRestartable?: boolean;
}

export interface ICountry {
  id: TCountryCode;
  name: string;
  flag: IUpload | null;
}

export interface ITask {
  id: number;
  step?: IStep;
  app?: IApp;
  user?: IUser | null;
  status: TTaskStatus;
  createdAt: TDateTime;
  updatedAt: TDateTime;
}

export interface INotification {
  id: number;
  icon: IUpload | null;
  message: string;
  extra: Record<string, any>;
  isNew: boolean;
  createdAt: TDateTime;
}

export interface IStep {
  id: number;
  name: string;
  description?: string | null;
  requiredFields?: IField[];
  formFields?: IField[];
  users?: IUser[];
  automationScript: string | null;
}

export interface IPushUser {
  id: number;
  push: IPush;
  isActive: boolean;
}

export interface IStream {
  id: number;
  user?: IUser;
  app?: IApp;
  name: string;
  isActive: boolean;
  url?: string;
  countries?: ICountry[];
}

export interface IDeepLinkRequest {
  appId: string;
  deviceId: string;
  deepLink?: string | null;
}

export interface IGetStoreItemRequest {
  key: string;
}

export interface IDeleteStoreItemRequest {
  key: string;
}

export interface ISetStoreItemRequest {
  value?: any | null;
  ttl?: number | null;
  key: string;
}

export interface IUpdateShareStatusRequest {
  status: TShareStatus;
  comment?: string | null;
}

export interface IGetSharesRequest {
  userId?: number | number[];
  appId?: number | number[];
  type?: TShareType | TShareType[];
  account?: string;
  status?: TShareStatus | TShareStatus[];
  sort?: 'id';
  order?: TSortOrder;
  page?: number;
  limit?: number;
}

export interface ICreateShareResponse {
  errors: string[];
  added: number;
}

export interface IDeleteShareRequest {
  appId?: number | null;
  type?: TShareType;
  account: string;
}

export interface ICreateShareRequest {
  appId: number;
  type: TShareType;
  account: string;
}

export interface IInitRequest {
  appId: string;
  platform: TPlatform;
  deviceId: string;
  language?: TLangCode | null;
  afId?: string | null;
  deepLink?: string | null;
}

export interface IUploadRequest {}

export interface IPartialChunkUploadRequest {
  id: string;
  chunk: string;
}

export interface IPartialUploadStatus {
  id: string;
  progress: number;
  upload: IUpload | null;
}

export interface IInitPartialUploadRequest {
  chunkSize: number;
  fileSize: number;
  fileName: string;
  fileType?: string;
  extra?: boolean;
  resize?: number | null;
  lock?: boolean;
}

export interface IUploadExtraImage {
  width: number;
  height: number;
  format: string;
}

export interface IUploadExtra {
  name: string;
  mime: string;
  size: number;
  image: IUploadExtraImage | null;
}

export interface IUpdateFCMRequest {
  deviceId: string;
  fcm: string;
}

export interface IProxyItem {
  app: IApp;
  proxy: string | null;
  proxyOrder: number | null;
}

export interface IGetStatsRequest {
  group: 'app_id' | 'stream_id' | 'user_id' | 'country_id' | 'date' | 'platform' | 'source';
  appId: number | null;
  streamId: number | null;
  userId: number | null;
  countryId: TCountryCode | null;
  platform: TPlatform | null;
  source: string | null;
  periodBegin: TDate | null;
  periodEnd: TDate | null;
  sort: 'date' | 'installs' | 'views' | 'registrations' | 'purchases';
  order: TSortOrder;
}

export interface IGetPushStatsResponse {
  params: IGetPushStatsRequest;
  data: IPushStatsDataRow[];
}

export interface IStatsDataRow {
  key: number;
  id: number | string | null;
  name: string;
  icon: IUpload | null;
  installs: number;
  views: number;
  registrations: number;
  purchases: number;
}

export interface IPushStatsDataRow {
  key: number;
  id: number | string | null;
  name: string;
  icon: IUpload | null;
  sent: number;
  skip: number;
  delivered: number;
  failed: number;
  clicks: number;
}

export interface IGetStatsResponse {
  params: IGetStatsRequest;
  data: IStatsDataRow[];
}

export interface IGetPushStatsRequest {
  group: 'push_id' | 'user_id' | 'app_id' | 'category_id' | 'country_id' | 'stream_id' | 'date' | 'trigger';
  pushId: number | null;
  userId: number | null;
  appId: number | null;
  categoryId: number | null;
  countryId: TCountryCode | null;
  streamId: number | null;
  trigger: string | null;
  periodBegin: TDate | null;
  periodEnd: TDate | null;
  sort: 'date' | 'sent' | 'skip' | 'delivered' | 'failed' | 'clicks';
  order: TSortOrder;
}

export interface ICreateUserRequest {
  email: string;
  password: string;
  status?: TUserStatus;
  name?: string | null;
  avatarId?: string | null;
  role?: TUserRole;
  stepIds?: number[];
  botId?: number | null;
  comment?: string | null;
  contactInfo?: string | null;
  appIds?: number[];
}

export interface IGetUsersRequest {
  query?: string;
  role?: TUserRole | TUserRole[];
  status?: TUserStatus | TUserStatus[];
  appId?: number | number[];
  sort?: 'id' | 'email' | 'name';
  order?: TSortOrder;
  page?: number;
  limit?: number;
}

export interface IUpdateUserRequest {
  email?: string;
  password?: string;
  name?: string | null;
  botId?: number | null;
  avatarId?: string | null;
  role?: TUserRole;
  stepIds?: number[];
  appIds?: number[];
  status?: TUserStatus;
  comment?: string | null;
  contactInfo?: string | null;
}

export interface IUpdateCurrentUserRequest {
  email?: string;
  password?: string;
  name?: string | null;
  avatarId?: string | null;
  botId?: number | null;
  contactInfo?: string | null;
}

export interface ILoginRequest {
  email: string;
  password: string;
  roles?: TUserRole[] | null;
}

export interface IAppsFlyerRequest {
  deviceId: string;
  data: {};
  afId?: string | null;
  deepLink?: string | null;
}

export interface ICreateUserGroupRequest {
  name: string;
  userIds?: number[];
}

export interface IUpdateUserGroupRequest {
  name?: string;
  userIds?: number[];
}

export interface IInitResponse {
  enabled: boolean;
  url: string | null;
}

export interface IUpdateAppFieldRequest {
  value?: any | null;
}

export interface ICreateTagRequest {
  name: string;
}

export interface ISendEventsRequest {
  registration?: boolean;
  purchase?: boolean;
  deviceIds: IDevice['id'][];
}

export interface IGetAppsRequest {
  query?: string;
  status?: TAppStatus | TAppStatus[];
  lang?: TLangCode | TLangCode[];
  categoryId?: number | number[];
  tagId?: number | number[];
  sort?: 'id' | 'name' | 'afCounter' | 'progress' | 'scheduledAt';
  order?: TSortOrder;
  scheduledAt?: TDateTime | null;
  source?: TEnabledSource | TEnabledSource[];
  page?: number;
  limit?: number;
  groups?: TGroup[];
}

export interface ISetAppStatusRequest {
  status: TAppStatus;
}

export interface IGetSimpleAppsRequest {
  status?: TAppStatus | TAppStatus[];
  preset?: 'start-process' | null;
}

export interface IUpdateAppRequest {
  categoryId?: number;
  description?: string;
  langCodes?: [];
  platform?: TPlatform;
  exclusiveUserId?: number | null;
  userIds?: number[];
  tagIds?: number[];
  sources?: TEnabledSource[];
  scheduledAt?: string | null;
}

export interface IRestartAppFlowRequest {
  fieldIds: number[];
}

export interface ISetAppFieldValueRequest {
  key: IField['key'];
  value?: any | null;
  lang?: ILang['id'] | null;
}

export interface ICreateAppRequest {
  categoryId: number;
  description: string;
  langCodes: [];
  platform?: TPlatform;
  tagIds?: number[];
  count?: number;
}

export interface ICreateAppHistoryRecordRequest {
  createdAt: string;
  message: string;
  comment?: string | null;
}

export interface IBroadcastAppRequest {
  message: string;
}

export interface IGetFullAppResponse {
  app: IApp;
  formFields: IAppField[];
}

export interface IUpdateAppHistoryRecordRequest {
  createdAt?: string;
  message?: string;
  comment?: string | null;
}

export interface ICloneAppRequest {
  fieldIds: number[];
  flowId?: number;
}

export interface IGetFieldsRequest {
  isClonable?: boolean;
  isRestartable?: boolean;
}

export interface IGroupedFields {
  group: IFieldGroup | null;
  fields: [];
}

export interface ICreateFieldRequest {
  fieldGroupId?: number | null;
  key: string;
  type: TFieldType;
  name: string;
  description?: string | null;
  isTranslatable?: boolean;
  isClonable?: boolean;
  isRestartable?: boolean;
  appField?: TAppField | null;
  config?: Record<string, any>;
}

export interface IUpdateFieldRequest {
  fieldGroupId?: number | null;
  key?: string;
  type?: TFieldType;
  name?: string;
  description?: string | null;
  isTranslatable?: boolean;
  isClonable?: boolean;
  isRestartable?: boolean;
  appField?: TAppField | null;
  config?: Record<string, any>;
}

export interface IGetNotificationsResponse {
  notifications: INotification[];
  badges: Record<TBadge, number>;
}

export interface IUploadEntryRequest {
  uploadId: string;
  targetPath: string;
  refreshHash?: boolean;
}

export interface IUpdateJsonRequest {
  json: Record<string, any>;
}

export interface IProjectEntry {
  type: 'file' | 'folder';
  name: string;
  size: number;
}

export interface ICloneProjectRequest {
  appId: number;
  name?: string | null;
}

export interface IGetProjectsRequest {
  gameId?: number;
  userId?: number;
  page?: number;
  limit?: number;
}

export interface IProjectEntryRequest {
  path: string;
}

export interface ICreateProjectRequest {
  gameId: number;
  appId: number;
  name?: string | null;
}

export interface IUpdateVarsRequest {
  vars: Record<string, any>;
}

export interface IUpdateSystemPushStatusRequest {
  isActive?: boolean;
}

export interface IPushTranslation {
  lang: TLangCode;
  title: string;
  body: string;
}

export interface IGetSystemPushesRequest {
  page?: number;
  limit?: number;
}

export interface ICreatePushAutomationRequest {
  type: TPushAutomationType;
  time?: TShortTime | null;
  days?: TDayOfWeek[];
  event?: TPushAutomationEvent | null;
  eventTime?: number | null;
  eventTimeFactor?: TTimeFactor | null;
  isActive?: boolean;
}

export interface IGetPushesRequest {
  mode?: 'push' | 'template';
  categoryId?: number | number[];
  day?: TDayOfWeek | TDayOfWeek[];
  lang?: TLangCode | TLangCode[];
  appId?: number | number[];
  streamId?: number | number[];
  sort?: 'id' | 'name';
  order?: TSortOrder;
  page?: number;
  limit?: number;
}

export interface IUpdatePushAutomationRequest {
  type?: TPushAutomationType | null;
  time?: TShortTime | null;
  days?: TDayOfWeek[];
  event?: TPushAutomationEvent | null;
  eventTime?: number | null;
  eventTimeFactor?: TTimeFactor | null;
  isActive?: boolean;
}

export interface IUpdatePushRequest {
  categoryIds?: number[];
  appIds?: number[];
  streamIds?: number[];
  name?: string;
  appId?: number;
  streamId?: number;
  iconId?: string | null;
  imageId?: string | null;
  title?: string;
  body?: string;
  translations?: IPushTranslation[];
}

export interface ICreatePushRequest {
  categoryIds?: number[];
  appIds?: number[];
  streamIds?: number[];
  name: string;
  iconId?: string | null;
  imageId?: string | null;
  title: string;
  body: string;
  translations?: IPushTranslation[];
}

export interface IGetGamesRequest {
  query?: string;
  isActive?: boolean;
  page?: number;
  limit?: number;
}

export interface IAddGameRequest {
  name?: string | null;
  previewId?: string | null;
  zipId: string;
  isActive?: boolean;
}

export interface IUpdateGameRequest {
  name?: string;
  previewId?: string;
  zipId?: string;
  isActive?: boolean;
}

export interface ICreateStreamRequest {
  appId: number;
  name: string;
  url?: string;
  countryCodes?: ICountry['id'][];
  isActive?: boolean;
}

export interface IUpdateStreamRequest {
  name?: string;
  url?: string;
  countryCodes?: ICountry['id'][];
  isActive?: boolean;
}

export interface IGetStreamsRequest {
  appId?: number | number[];
  userId?: number | number[];
  isActive?: boolean;
  sort?: 'id' | 'name';
  order?: TSortOrder;
  page?: number;
  limit?: number;
}

export interface IStartNewFlowProcessRequest {
  appId: number | number[];
  flowId: number;
}

export interface IGetFlowProcessesRequest {
  appId?: number | number[];
  flowId?: number | number[];
  status?: TFlowProcessStatus | TFlowProcessStatus[];
  page?: number;
  limit?: number;
}

export interface ICreateFlowRequest {
  name: string;
  stepIds: number[];
}

export interface IUpdateFlowRequest {
  name?: string;
  stepIds?: number[];
}

export interface IUpdateFlowProcessRequest {
  status?: TFlowProcessStatus;
}

export interface IGetTasksRequest {
  status?: TTaskStatus | TTaskStatus[];
  appId?: number | number[];
  userId?: number | number[];
  stepId?: number | number[];
  sort?: 'createdAt' | 'progress' | 'scheduledAt';
  order?: TSortOrder;
  page?: number;
  limit?: number;
}

export interface IGetTaskResponse {
  task: ITask;
  requiredFields: IAppField[];
  formFields: IAppField[];
}

export interface IUpdateTasksRequest {
  formFields: any;
  tagIds?: number[];
  comment?: string | null;
}

export interface IUpdateStepRequest {
  name?: string;
  description?: string | null;
  requiredFieldIds?: number[];
  formFieldIds?: number[];
  userIds?: number[];
  automationScript?: TAutomationScript | null;
}

export interface ICreateStepRequest {
  name: string;
  description?: string | null;
  requiredFieldIds?: number[];
  formFieldIds: number[];
  userIds?: number[];
  automationScript?: TAutomationScript | null;
}

export type TGroup =
  | 'main'
  | 'flow:steps'
  | 'flow:full'
  | 'lang:active'
  | 'push:user'
  | 'icon'
  | 'device:app'
  | 'device:full'
  | 'device:country'
  | 'device:stream'
  | 'user-group:users'
  | 'game:zip'
  | 'game:full'
  | 'game:config'
  | 'project:game'
  | 'project:full'
  | 'project:author'
  | 'project:app'
  | 'project:dates'
  | 'project:variables'
  | 'project:json'
  | 'group:fields'
  | 'process:flow'
  | 'process:full'
  | 'process:app'
  | 'app:full'
  | 'app:langs'
  | 'app:description'
  | 'app:firebase'
  | 'app:category'
  | 'app:users'
  | 'app:exclusive'
  | 'app:users_count'
  | 'app:af_counter'
  | 'app:facebook'
  | 'app:facebook_shares_count'
  | 'app:one_link'
  | 'app:tags'
  | 'app:sources'
  | 'upload:full'
  | 'user:steps'
  | 'user:full'
  | 'user:apps'
  | 'user:comment'
  | 'user:profile'
  | 'user:contacts'
  | 'user:user-groups'
  | 'field:full'
  | 'task:full'
  | 'task:step'
  | 'task:app'
  | 'task:user'
  | 'step:full'
  | 'step:description'
  | 'step:required_fields'
  | 'step:form_fields'
  | 'step:users'
  | 'stream:user'
  | 'stream:full'
  | 'stream:app'
  | 'stream:url'
  | 'stream:countries';

class Store {
  public static getItem = (): Promise<any> => rest.get(`/store`);

  public static setItem = (request: ISetStoreItemRequest): Promise<boolean> => rest.put(`/store`, request);

  public static deleteItem = (): Promise<number> => rest.delete(`/store`);
}

class Tasks {
  public static getTasks = (request: IGetTasksRequest): Promise<IPagedData<ITask>> => rest.get(`/tasks`, request);

  public static getTask = (task: TIdentifier): Promise<IGetTaskResponse> => rest.get(`/tasks/${task}`);

  public static takeTask = (task: TIdentifier): Promise<ITask> => rest.post(`/tasks/${task}/take`);

  public static releaseTask = (task: TIdentifier): Promise<ITask> => rest.post(`/tasks/${task}/release`);

  public static updateTask = (task: TIdentifier, request: IUpdateTasksRequest): Promise<boolean> =>
    rest.patch(`/tasks/${task}`, request);

  public static deleteTask = (task: TIdentifier): Promise<boolean> => rest.delete(`/tasks/${task}/release`);
}

class Main {
  public static init = (request: IInitRequest): Promise<IInitResponse> => rest.post(`/init`, request);

  public static appslyer = (request: IAppsFlyerRequest): Promise<IInitResponse> => rest.post(`/appsflyer`, request);

  public static fcm = (request: IUpdateFCMRequest): Promise<boolean> => rest.post(`/fcm`, request);

  public static getDeviceMessages = (device: TIdentifier): Promise<unknown> => rest.get(`/device/${device}/messages`);

  public static deep = (request: IDeepLinkRequest): Promise<IInitResponse> => rest.post(`/deep`, request);
}

class Notifications {
  public static getNotifications = (): Promise<INotification[]> => rest.get(`/notifications`);

  public static getNotificationsWithBadges = (): Promise<IGetNotificationsResponse> =>
    rest.get(`/notifications/badged`);

  public static readNotification = (notification: TIdentifier): Promise<INotification> =>
    rest.patch(`/notifications/${notification}`);

  public static deleteNotification = (notification: TIdentifier): Promise<boolean> =>
    rest.delete(`/notifications/${notification}`);

  public static readAllNotification = (): Promise<boolean> => rest.patch(`/notifications`);

  public static deleteAllNotifications = (): Promise<boolean> => rest.delete(`/notifications`);
}

class Fields {
  public static getFields = (request: IGetFieldsRequest): Promise<IField[]> => rest.get(`/fields`, request);

  public static getSimpleFields = (): Promise<IField[]> => rest.get(`/fields/simple`);

  public static getMacrosList = (): Promise<{ key: string; name: string }[]> => rest.get(`/fields/macros`);

  public static getGroupedField = (): Promise<IGroupedFields[]> => rest.get(`/fields/grouped`);

  public static getFieldGroups = (): Promise<IFieldGroup[]> => rest.get(`/fields/groups`);

  public static createField = (request: ICreateFieldRequest): Promise<IField> => rest.post(`/fields`, request);

  public static getField = (field: TIdentifier): Promise<IField> => rest.get(`/fields/${field}`);

  public static updateField = (field: TIdentifier, request: IUpdateFieldRequest): Promise<IField> =>
    rest.patch(`/fields/${field}`, request);

  public static deleteField = (field: TIdentifier): Promise<boolean> => rest.delete(`/fields/${field}`);
}

class Stats {
  public static getStats = (request: IGetStatsRequest): Promise<IGetStatsResponse> => rest.get(`/stats`, request);

  public static getStatsSources = (): Promise<string[]> => rest.get(`/stats/sources`);

  public static getPushStats = (request: IGetPushStatsRequest): Promise<IGetPushStatsResponse> =>
    rest.get(`/stats/push`, request);
}

class Processes {
  public static getFlowProcessesList = (request: IGetFlowProcessesRequest): Promise<IPagedData<IFlowProcess>> =>
    rest.get(`/processes`, request);

  public static startNewFlowProcess = (request: IStartNewFlowProcessRequest): Promise<number> =>
    rest.post(`/processes`, request);

  public static getFlowProcess = (process: TIdentifier): Promise<IFlowProcess> => rest.get(`/processes/${process}`);

  public static updateFlowProcess = (process: TIdentifier, request: IUpdateFlowProcessRequest): Promise<IFlowProcess> =>
    rest.patch(`/processes/${process}`, request);

  public static checkFlowProcessTasks = (process: TIdentifier): Promise<boolean> =>
    rest.post(`/processes/${process}/check`);

  public static deleteFlowProcess = (process: TIdentifier): Promise<boolean> => rest.delete(`/processes/${process}`);
}

class Auth {
  public static login = (request: ILoginRequest): Promise<ISession> => rest.post(`/auth`, request);

  public static logout = (): Promise<boolean> => rest.delete(`/auth`);

  public static logoutByToken = (session: TIdentifier): Promise<boolean> => rest.delete(`/auth/${session}`);
}

class Streams {
  public static index = (request: IGetStreamsRequest): Promise<IPagedData<IStream>> => rest.get(`/streams`, request);

  public static getStream = (stream: TIdentifier): Promise<IStream> => rest.get(`/streams/${stream}`);

  public static createStream = (request: ICreateStreamRequest): Promise<IStream> => rest.post(`/streams`, request);

  public static updateStream = (stream: TIdentifier, request: IUpdateStreamRequest): Promise<IStream> =>
    rest.patch(`/streams/${stream}`, request);

  public static deleteStream = (stream: TIdentifier): Promise<boolean> => rest.delete(`/streams/${stream}`);
}

class My {
  public static apps = (request: IGetAppsRequest): Promise<IPagedData<IApp>> => rest.get(`/my/apps`, request);
}

class SmsMessages {
  public static getSmsList = (): Promise<ISms[]> => rest.get(`/sms`);
}

class Pushes {
  public static getPushes = (request: IGetPushesRequest): Promise<IPagedData<IPush>> => rest.get(`/push`, request);

  public static getSystemPushes = (request: IGetSystemPushesRequest): Promise<IPagedData<IPushUser>> =>
    rest.get(`/push/system`, request);

  public static updateSystemPushStatus = (
    push: TIdentifier,
    request: IUpdateSystemPushStatusRequest
  ): Promise<IPushUser> => rest.patch(`/push/system/${push}`, request);

  public static createPush = (request: ICreatePushRequest): Promise<IPush> => rest.post(`/push`, request);

  public static getPush = (push: TIdentifier): Promise<IPush> => rest.get(`/push/${push}`);

  public static getPushAutomations = (push: TIdentifier): Promise<IPushAutomation[]> =>
    rest.get(`/push/${push}/automations`);

  public static createPushAutomation = (
    push: TIdentifier,
    request: ICreatePushAutomationRequest
  ): Promise<IPushAutomation> => rest.post(`/push/${push}/automations`, request);

  public static getPushAutomation = (push: TIdentifier, automation: TIdentifier): Promise<IPushAutomation> =>
    rest.get(`/push/${push}/automations/${automation}`);

  public static updatePushAutomation = (
    push: TIdentifier,
    automation: TIdentifier,
    request: IUpdatePushAutomationRequest
  ): Promise<IPushAutomation> => rest.patch(`/push/${push}/automations/${automation}`, request);

  public static deletePushAutomation = (push: TIdentifier, automation: TIdentifier): Promise<boolean> =>
    rest.delete(`/push/${push}/automations/${automation}`);

  public static clonePush = (push: TIdentifier): Promise<IPush> => rest.post(`/push/${push}/clone`);

  public static startPush = (push: TIdentifier): Promise<boolean> => rest.post(`/push/${push}/start`);

  public static updatePush = (push: TIdentifier, request: IUpdatePushRequest): Promise<IPush> =>
    rest.patch(`/push/${push}`, request);

  public static deletePush = (push: TIdentifier): Promise<IPush> => rest.delete(`/push/${push}`);
}

class Flows {
  public static getFlowsList = (): Promise<IFlow[]> => rest.get(`/flows`);

  public static createFlow = (request: ICreateFlowRequest): Promise<IFlow> => rest.post(`/flows`, request);

  public static getFlow = (flow: TIdentifier): Promise<IFlow> => rest.get(`/flows/${flow}`);

  public static updateFlow = (flow: TIdentifier, request: IUpdateFlowRequest): Promise<IFlow> =>
    rest.patch(`/flows/${flow}`, request);

  public static deleteFlow = (flow: TIdentifier): Promise<boolean> => rest.delete(`/flows/${flow}`);
}

class Proxies {
  public static getBalance = (): Promise<number | null> => rest.get(`/proxies/balance`);

  public static getList = (): Promise<IProxyItem[]> => rest.get(`/proxies`);

  public static deleteProxy = (app: TIdentifier): Promise<boolean> => rest.delete(`/proxies/${app}`);
}

class Projects {
  public static getProjectsList = (request: IGetProjectsRequest): Promise<IPagedData<IProject>> =>
    rest.get(`/projects`, request);

  public static createProject = (request: ICreateProjectRequest): Promise<IProject> => rest.post(`/projects`, request);

  public static getProject = (project: TIdentifier): Promise<IProject> => rest.get(`/projects/${project}`);

  public static getProjectEntries = (project: TIdentifier, request: IProjectEntryRequest): Promise<IProjectEntry[]> =>
    rest.get(`/projects/${project}/entries`, request);

  public static tinifyEntry = (
    project: TIdentifier,
    request: IProjectEntryRequest
  ): Promise<{ before: number; after: number; ratio: number; project: IProject }> =>
    rest.patch(`/projects/${project}/tinify`, request);

  public static deleteEntry = (project: TIdentifier, request: IProjectEntryRequest): Promise<boolean> =>
    rest.delete(`/projects/${project}/entry`, request);

  public static downloadEntry = (project: TIdentifier, path: string): void => {
    window.location.href = `${endpoint}/projects/${project}/entry?path=${encodeURIComponent(path)}`;
  };

  public static cloneProject = (project: TIdentifier, request: ICloneProjectRequest): Promise<IProject> =>
    rest.post(`/projects/${project}/clone`, request);

  public static uploadEntry = (project: TIdentifier, request: IUploadEntryRequest): Promise<IProject> =>
    rest.post(`/projects/${project}/entry`, request);

  public static updateVars = (project: TIdentifier, request: IUpdateVarsRequest): Promise<IProject> =>
    rest.patch(`/projects/${project}/vars`, request);

  public static updateJson = (project: TIdentifier, request: IUpdateJsonRequest): Promise<IProject> =>
    rest.put(`/projects/${project}/json`, request);

  public static exportProject = (project: TIdentifier): Promise<IUpload> => rest.get(`/projects/${project}/zip`);

  public static deleteProject = (project: TIdentifier): Promise<boolean> => rest.delete(`/projects/${project}`);

  public static cleanupProjects = (): Promise<number> => rest.delete(`/projects`);
}

class Callbacks {
  public static pushEvents = (event: TIdentifier, reportId: TIdentifier): Promise<unknown> =>
    rest.post(`/callback/${event}/${reportId}`);

  public static conversionEvents = (event: TIdentifier, device: TIdentifier): Promise<unknown> =>
    rest.post(`/callback/${event}/${device}`);
}

class Shares {
  public static getShares = (request: IGetSharesRequest): Promise<IPagedData<IShare>> => rest.get(`/share`, request);

  public static deleteShares = (request: IDeleteShareRequest): Promise<number> => rest.delete(`/share`, request);

  public static createShare = (request: ICreateShareRequest): Promise<ICreateShareResponse> =>
    rest.post(`/share`, request);

  public static updateShareStatus = (share: TIdentifier, request: IUpdateShareStatusRequest): Promise<boolean> =>
    rest.patch(`/share/${share}/status`, request);

  public static deleteShare = (share: TIdentifier): Promise<boolean> => rest.delete(`/share/${share}`);
}

class UserGroups {
  public static getList = (): Promise<IUserGroup[]> => rest.get(`/user-groups`);

  public static getById = (group: TIdentifier): Promise<IUserGroup> => rest.get(`/user-groups/${group}`);

  public static create = (request: ICreateUserGroupRequest): Promise<IUserGroup> => rest.post(`/user-groups`, request);

  public static update = (group: TIdentifier, request: IUpdateUserGroupRequest): Promise<IUserGroup> =>
    rest.patch(`/user-groups/${group}`, request);

  public static delete = (group: TIdentifier): Promise<boolean> => rest.delete(`/user-groups/${group}`);
}

class AppFields {
  public static updateAppField = (appField: TIdentifier, request: IUpdateAppFieldRequest): Promise<unknown> =>
    rest.patch(`/app-fields/${appField}`, request);
}

class Uploads {
  public static uploadJson = (request: IUploadRequest): Promise<IUpload> => rest.post(`/upload/base64`, request);

  public static uploadForm = (request: FormData): Promise<IUpload> => rest.post(`/upload/form`, request);

  public static beginPartial = (request: IInitPartialUploadRequest): Promise<IPartialUploadStatus> =>
    rest.post(`/upload/partial`, request);

  public static chunkPartial = (request: IPartialChunkUploadRequest): Promise<IPartialUploadStatus> =>
    rest.patch(`/upload/partial`, request);

  public static getUpload = (file: TIdentifier): Promise<IUpload> => rest.get(`/upload/${file}`);
}

class Langs {
  public static index = (): Promise<ILang[]> => rest.get(`/langs`);

  public static activate = (lang: TIdentifier): Promise<ILang> => rest.post(`/langs/${lang}/activate`);

  public static deactivate = (lang: TIdentifier): Promise<ILang> => rest.post(`/langs/${lang}/deactivate`);
}

class Utils {
  public static test = (): Promise<unknown> => rest.get(`/test`);
}

class Devices {
  public static getLatest = (app: TIdentifier): Promise<IDevice[]> => rest.get(`/devices/latest/${app}`);

  public static sendEvents = (request: ISendEventsRequest): Promise<boolean> => rest.post(`/devices/events`, request);
}

class Tags {
  public static getTagsList = (): Promise<ITag[]> => rest.get(`/tags`);

  public static createTag = (request: ICreateTagRequest): Promise<ITag> => rest.post(`/tags`, request);

  public static updateTag = (tag: TIdentifier, request: ICreateTagRequest): Promise<ITag> =>
    rest.patch(`/tags/${tag}`, request);

  public static deleteTag = (tag: TIdentifier): Promise<boolean> => rest.delete(`/tags/${tag}`);
}

class Categories {
  public static index = (): Promise<ICategory[]> => rest.get(`/categories`);
}

class Apps {
  public static index = (request: IGetAppsRequest): Promise<IPagedData<IApp>> => rest.get(`/apps`, request);

  public static getSimpleList = (request: IGetSimpleAppsRequest): Promise<IApp[]> => rest.get(`/apps/simple`, request);

  public static create = (request: ICreateAppRequest): Promise<boolean> => rest.post(`/apps`, request);

  public static getApp = (app: TIdentifier): Promise<IApp> => rest.get(`/apps/${app}`);

  public static getAppHistory = (app: TIdentifier): Promise<IAppHistoryRecord[]> => rest.get(`/apps/${app}/history`);

  public static createAppHistoryRecord = (
    app: TIdentifier,
    request: ICreateAppHistoryRecordRequest
  ): Promise<IAppHistoryRecord> => rest.post(`/apps/${app}/history`, request);

  public static getAppHistoryRecord = (app: TIdentifier, record: TIdentifier): Promise<IAppHistoryRecord> =>
    rest.get(`/apps/${app}/history/${record}`);

  public static updateAppHistoryRecord = (
    app: TIdentifier,
    record: TIdentifier,
    request: IUpdateAppHistoryRecordRequest
  ): Promise<IAppHistoryRecord> => rest.patch(`/apps/${app}/history/${record}`, request);

  public static deleteAppHistoryRecord = (app: TIdentifier, record: TIdentifier): Promise<boolean> =>
    rest.delete(`/apps/${app}/history/${record}`);

  public static getSimpleApp = (app: TIdentifier): Promise<unknown> => rest.get(`/apps/${app}/values`);

  public static setAppFieldValue = (app: TIdentifier, request: ISetAppFieldValueRequest): Promise<boolean> =>
    rest.patch(`/apps/${app}/values`, request);

  public static getFullApp = (app: TIdentifier): Promise<IGetFullAppResponse> => rest.get(`/apps/${app}/full`);

  public static updateApp = (app: TIdentifier, request: IUpdateAppRequest): Promise<IApp> =>
    rest.patch(`/apps/${app}`, request);

  public static deleteAppUsers = (app: TIdentifier): Promise<boolean> => rest.delete(`/apps/${app}/users`);

  public static setAppStatus = (app: TIdentifier, request: ISetAppStatusRequest): Promise<boolean> =>
    rest.patch(`/apps/${app}/status`, request);

  public static getAppLink = (app: TIdentifier): Promise<string> => rest.get(`/apps/${app}/link`);

  public static broadcastNotification = (app: TIdentifier, request: IBroadcastAppRequest): Promise<number> =>
    rest.post(`/apps/${app}/broadcast`, request);

  public static cloneApp = (app: TIdentifier, request: ICloneAppRequest): Promise<IApp> =>
    rest.post(`/apps/${app}/clone`, request);

  public static restartFlow = (app: TIdentifier, request: IRestartAppFlowRequest): Promise<IApp> =>
    rest.post(`/apps/${app}/restart`, request);

  public static deleteApp = (app: TIdentifier): Promise<boolean> => rest.delete(`/apps/${app}`);
}

class Steps {
  public static getSteps = (): Promise<IStep[]> => rest.get(`/steps`);

  public static createStep = (request: ICreateStepRequest): Promise<IStep> => rest.post(`/steps`, request);

  public static getStep = (step: TIdentifier): Promise<IStep> => rest.get(`/steps/${step}`);

  public static updateStep = (step: TIdentifier, request: IUpdateStepRequest): Promise<IStep> =>
    rest.patch(`/steps/${step}`, request);

  public static deleteStep = (step: TIdentifier): Promise<boolean> => rest.delete(`/steps/${step}`);
}

class Users {
  public static getUsers = (request: IGetUsersRequest): Promise<IPagedData<IUser>> => rest.get(`/users`, request);

  public static createUser = (request: ICreateUserRequest): Promise<IUser> => rest.post(`/users`, request);

  public static getCurrentUser = (): Promise<IUser> => rest.get(`/users/current`);

  public static updateCurrentUser = (request: IUpdateCurrentUserRequest): Promise<IUser> =>
    rest.patch(`/users/current`, request);

  public static getUserById = (user: TIdentifier): Promise<IUser> => rest.get(`/users/${user}`);

  public static updateUser = (user: TIdentifier, request: IUpdateUserRequest): Promise<IUser> =>
    rest.patch(`/users/${user}`, request);

  public static deleteUser = (user: TIdentifier): Promise<boolean> => rest.delete(`/users/${user}`);
}

class Games {
  public static getGamesList = (request: IGetGamesRequest): Promise<IPagedData<IGame>> => rest.get(`/games`, request);

  public static addGame = (request: IAddGameRequest): Promise<IGame> => rest.post(`/games`, request);

  public static getGame = (game: TIdentifier): Promise<IGame> => rest.get(`/games/${game}`);

  public static updateGame = (game: TIdentifier, request: IUpdateGameRequest): Promise<IGame> =>
    rest.patch(`/games/${game}`, request);

  public static deleteGame = (game: TIdentifier): Promise<boolean> => rest.delete(`/games/${game}`);
}

class Reports {}

class Countries {
  public static index = (): Promise<ICountry[]> => rest.get(`/countries`);
}

export const API = {
  Store,
  Tasks,
  Main,
  Notifications,
  Fields,
  Stats,
  Processes,
  Auth,
  Streams,
  My,
  SmsMessages,
  Pushes,
  Flows,
  Proxies,
  Projects,
  Callbacks,
  Shares,
  UserGroups,
  AppFields,
  Uploads,
  Langs,
  Utils,
  Devices,
  Tags,
  Categories,
  Apps,
  Steps,
  Users,
  Games,
  Reports,
  Countries,
};