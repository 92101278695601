import { toJS } from 'mobx';

import packageJSON from '../../package.json';

type METHOD = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export class RestClient {
  private readonly url: string;
  private token: string | null = window.localStorage.getItem('token');
  private status: number = 0;
  public debug: boolean;

  constructor(url: string, debug: boolean = false) {
    this.url = url;
    this.debug = debug;
  }

  public getUrl = (): string => this.url;

  public setToken = (token: string | null): this => {
    if (token) {
      window.localStorage.setItem('token', token);
    } else {
      window.localStorage.removeItem('token');
    }

    this.token = token;
    return this;
  };

  public getToken = (): string | null => {
    return this.token;
  };

  public getStatus = (): number => {
    return this.status;
  };

  public get = (endpoint: string, payload?: unknown): Promise<any> => {
    return this.request('GET', endpoint, payload);
  };

  public post = (endpoint: string, payload?: unknown): Promise<any> => {
    return this.request('POST', endpoint, payload);
  };

  public put = (endpoint: string, payload?: unknown): Promise<any> => {
    return this.request('PUT', endpoint, payload);
  };

  public patch = (endpoint: string, payload?: unknown): Promise<any> => {
    return this.request('PATCH', endpoint, payload);
  };

  public delete = (endpoint: string, payload?: unknown): Promise<any> => {
    return this.request('DELETE', endpoint, payload);
  };

  private request = (method: METHOD, endpoint: string, payload: unknown): Promise<unknown> => {
    if (this.debug) {
      console.info(method, endpoint, payload ? toJS(payload) : '');
    }

    return new Promise((resolve, reject) => {
      const processReject = (error: string) => {
        reject(error);
      };

      const options: RequestInit & { headers: Record<string, string> } = {
        method: method.toUpperCase(),
        mode: 'cors',
        headers: {
          accept: 'app/json',
          'client-version': packageJSON.version,
          'client-name': packageJSON.name,
        },
      };

      if (payload instanceof FormData) {
        options.body = payload;
      } else {
        options.headers['content-type'] = 'app/json';
        if (payload && method.toUpperCase() !== 'GET') options.body = JSON.stringify(payload);
      }

      if (this.token) {
        options.headers['authorization'] = 'Bearer ' + this.token;
      }

      this.status = 0;

      if (payload && method.toUpperCase() === 'GET') {
        endpoint += '?__payload=' + encodeURIComponent(JSON.stringify(payload));
      }

      fetch(this.url + endpoint, options)
        .then((response) => {
          this.status = response.status;

          response
            .json()
            .then((data) => {
              if (data.error) {
                processReject(data.error);
              } else {
                resolve(data.result);
              }
            })
            .catch(processReject);
        })
        .catch(processReject);
    });
  };
}
