import './index.scss';

import { RightOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import block from 'bem-cn';
import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import { colors } from '../../constants';
import { ISidebarLink } from '../../interfaces';
import { isGranted } from '../../modules/isGranted';
import { store } from '../../store';

const b = block('menu');

export const Menu = ({
  items,
  title,
  isChildren = false,
}: {
  items: ISidebarLink[];
  title?: string;
  isChildren?: boolean;
}) => (
  <div className={b({ children: isChildren })}>
    {title ? <h5 className={b('title')}>{title}</h5> : null}
    <ul className={b('ul')}>
      {items.map((link) => (
        <Fragment key={link.path}>
          <li className={b('li')}>
            <NavLink
              exact={Boolean(link.exact)}
              activeClassName={b('a', { active: true }).toString()}
              to={link.path}
              className={b('a').toString()}
            >
              <span className={b('icon')}>{link.icon || null}</span>
              <span className={b('text')}>{link.title}</span>
              {link.badge && store.badges[link.badge] ? (
                <Badge className={b('badge').toString()} color={colors.red} dot />
              ) : null}

              {link.children ? <RightOutlined style={{ fontSize: 10 }} /> : null}
            </NavLink>

            {link.children ? (
              <Menu items={link.children.filter((link) => isGranted(link.role))} isChildren={true} />
            ) : null}
          </li>
        </Fragment>
      ))}
    </ul>
  </div>
);
