import { Observer } from 'mobx-react';
import React, { PropsWithChildren, Suspense, lazy } from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { Loader } from '../components/loader';
import Private from '../components/private';
import { isGranted } from '../modules/isGranted';
import { Login } from './login';
import { MyApps } from './myApps';
import { Stats } from './stats';

const Fields = lazy(() => import('./fields').then(({ Fields }) => ({ default: Fields })));
const Steps = lazy(() => import('./steps').then(({ Steps }) => ({ default: Steps })));
const UserManage = lazy(() => import('./userManage').then(({ UserManage }) => ({ default: UserManage })));
const Register = lazy(() => import('./register').then(({ Register }) => ({ default: Register })));
const AppFields = lazy(() => import('./appFields').then(({ AppFields }) => ({ default: AppFields })));
const AppTags = lazy(() => import('./appTags').then(({ AppTags }) => ({ default: AppTags })));
const PushManage = lazy(() => import('./pushManage').then(({ PushManage }) => ({ default: PushManage })));
const PushSystem = lazy(() => import('./pushSystem').then(({ PushSystem }) => ({ default: PushSystem })));
const Users = lazy(() => import('./users').then(({ Users }) => ({ default: Users })));
const Share = lazy(() => import('./share').then(({ Share }) => ({ default: Share })));
const ShareAdmin = lazy(() => import('./shareAdmin').then(({ ShareAdmin }) => ({ default: ShareAdmin })));
const Task = lazy(() => import('./task').then(({ Task }) => ({ default: Task })));
const Tasks = lazy(() => import('./tasks').then(({ Tasks }) => ({ default: Tasks })));
const Apps = lazy(() => import('./apps').then(({ Apps }) => ({ default: Apps })));
const StreamManage = lazy(() => import('./streamManage').then(({ StreamManage }) => ({ default: StreamManage })));
const Proxy = lazy(() => import('./utils/proxies').then(({ Proxies }) => ({ default: Proxies })));
const Games = lazy(() => import('./utils/games').then(({ Games }) => ({ default: Games })));
const Project = lazy(() => import('./utils/project').then(({ Project }) => ({ default: Project })));
const Projects = lazy(() => import('./utils/projects').then(({ Projects }) => ({ default: Projects })));
const Profile = lazy(() => import('./profile').then(({ Profile }) => ({ default: Profile })));
const PushStatistics = lazy(() =>
  import('./pushStatistics').then(({ PushStatistics }) => ({ default: PushStatistics }))
);
const Flows = lazy(() => import('./flows').then(({ Flows }) => ({ default: Flows })));
const Pushes = lazy(() => import('./pushes').then(({ Pushes }) => ({ default: Pushes })));
const Streams = lazy(() => import('./streams').then(({ Streams }) => ({ default: Streams })));
const Processes = lazy(() => import('./processes').then(({ Processes }) => ({ default: Processes })));
const UserGroups = lazy(() => import('./users/UserGroups').then(({ UserGroups }) => ({ default: UserGroups })));

const AppUploadsReport = lazy(() =>
  import('./reports/appUploadsReport').then(({ AppUploadsReport }) => ({ default: AppUploadsReport }))
);

const AppUploadSchedule = lazy(() =>
  import('./reports/appUploadSchedule').then(({ AppUploadSchedule }) => ({ default: AppUploadSchedule }))
);

const NotFound = lazy(() => import('./notFound').then(({ NotFound }) => ({ default: NotFound })));
const AppsDashboard = lazy(() => import('./apps/dashboard').then(({ AppsDashboard }) => ({ default: AppsDashboard })));

export const Routes = () => (
  <Observer>
    {() => (
      <Router>
        <Suspense fallback={<Loader delay={1200} />}>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />

            <Private>
              <Suspense fallback={<Loader delay={1200} />}>
                <Switch>
                  {/*ADMIN ROUTES*/}
                  {isGranted('ROLE_ADMIN', <Route exact path="/flows" component={Flows} />)}
                  {isGranted('ROLE_ADMIN', <Route exact path="/processes" component={Processes} />)}
                  {isGranted(['ROLE_ADMIN', 'ROLE_MANAGER'], <Route exact path="/share" component={ShareAdmin} />)}

                  {isGranted(['ROLE_ADMIN', 'ROLE_EMPLOYEE'], <Route exact path="/utils" component={Games} />)}
                  {isGranted(['ROLE_EMPLOYEE', 'ROLE_ADMIN'], <Route exact path="/utils/games" component={Games} />)}
                  {isGranted(
                    ['ROLE_EMPLOYEE', 'ROLE_ADMIN'],
                    <Route exact path="/utils/games/:game/projects" component={Projects} />
                  )}
                  {isGranted(
                    ['ROLE_EMPLOYEE', 'ROLE_ADMIN'],
                    <Route exact path="/utils/games/:game/projects/:project" component={Project} />
                  )}
                  {isGranted('ROLE_ADMIN', <Route exact path="/utils/proxies" component={Proxy} />)}

                  {isGranted(
                    ['ROLE_ADMIN', 'ROLE_MANAGER'],
                    <Route
                      exact
                      path="/push-templates/manage/:pushId?"
                      component={(p: PropsWithChildren<any>) => <PushManage {...p} mode="template" />}
                    />
                  )}

                  {isGranted(
                    ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_EMPLOYEE'],
                    <Route exact path="/apps" component={Apps} />
                  )}
                  {isGranted('ROLE_ADMIN', <Route exact path="/apps/tags" component={AppTags} />)}
                  {isGranted(
                    ['ROLE_ADMIN', 'ROLE_EMPLOYEE'],
                    <Route exact path="/apps/:id/fields" component={AppFields} />
                  )}
                  {isGranted(['ROLE_ADMIN', 'ROLE_MANAGER'], <Route exact path="/users" component={Users} />)}
                  {isGranted(
                    ['ROLE_ADMIN', 'ROLE_MANAGER'],
                    <Route exact path="/users/manage/:id?" component={UserManage} />
                  )}

                  {isGranted(
                    ['ROLE_ADMIN', 'ROLE_MANAGER'],
                    <Route exact path="/users/groups" component={UserGroups} />
                  )}

                  {isGranted('ROLE_ADMIN', <Route exact path="/fields" component={Fields} />)}
                  {isGranted('ROLE_ADMIN', <Route exact path="/steps" component={Steps} />)}

                  {isGranted(
                    ['ROLE_ADMIN', 'ROLE_EMPLOYEE'],
                    <Route exact path="/reports" component={AppUploadSchedule} />
                  )}
                  {isGranted('ROLE_ADMIN', <Route exact path="/reports/apps/uploads" component={AppUploadsReport} />)}
                  {isGranted(
                    ['ROLE_ADMIN', 'ROLE_EMPLOYEE'],
                    <Route exact path="/reports/apps/schedule" component={AppUploadSchedule} />
                  )}
                  {isGranted(
                    ['ROLE_ADMIN', 'ROLE_MANAGER'],
                    <Route exact path="/apps/dashboard" component={AppsDashboard} />
                  )}

                  {/*PARTNER ROUTES*/}
                  {isGranted('ROLE_PARTNER', <Route exact path="/apps" component={MyApps} />)}
                  {isGranted('ROLE_PARTNER', <Route exact path="/apps/share/:id" component={Share} />)}
                  {isGranted('ROLE_PARTNER', <Route exact path="/push-system" component={PushSystem} />)}

                  {/*PARTNER AND ADMIN ROUTES*/}
                  {isGranted(
                    ['ROLE_PARTNER', 'ROLE_ADMIN', 'ROLE_MANAGER'],
                    <Route exact path="/push-statistics" component={PushStatistics} />
                  )}

                  {isGranted(
                    ['ROLE_PARTNER', 'ROLE_ADMIN', 'ROLE_MANAGER'],
                    <Route exact path="/pushes" component={Pushes} />
                  )}

                  {isGranted(
                    ['ROLE_PARTNER', 'ROLE_ADMIN', 'ROLE_MANAGER'],
                    <Route exact path="/pushes/manage/:pushId?" component={PushManage} />
                  )}
                  {isGranted(
                    ['ROLE_ADMIN', 'ROLE_PARTNER', 'ROLE_MANAGER'],
                    <Route
                      exact
                      path="/push-templates/"
                      component={(p: PropsWithChildren<any>) => <Pushes {...p} mode="template" />}
                    />
                  )}
                  {isGranted(
                    ['ROLE_PARTNER', 'ROLE_ADMIN', 'ROLE_MANAGER'],
                    <Route exact path="/" component={Stats} />
                  )}
                  {isGranted(
                    ['ROLE_PARTNER', 'ROLE_ADMIN', 'ROLE_MANAGER'],
                    <Route exact path="/streams" component={Streams} />
                  )}
                  {isGranted(
                    ['ROLE_PARTNER', 'ROLE_ADMIN', 'ROLE_MANAGER'],
                    <Route exact path="/streams/manage/:id?" component={StreamManage} />
                  )}

                  {/*EMPLOYEE AND ADMIN ROUTES*/}
                  {isGranted(['ROLE_EMPLOYEE', 'ROLE_ADMIN'], <Route exact path="/tasks" component={Tasks} />)}
                  {isGranted(['ROLE_EMPLOYEE', 'ROLE_ADMIN'], <Route exact path="/tasks/:id" component={Task} />)}

                  {/*GLOBAL ROUTES*/}
                  <Route exact path="/profile" component={Profile} />

                  {/*EMPLOYEE REDIRECT*/}
                  {isGranted(['ROLE_EMPLOYEE'], <Redirect to="tasks" from="/" exact />)}

                  <Route component={NotFound} />
                </Switch>
              </Suspense>
            </Private>
          </Switch>
        </Suspense>
      </Router>
    )}
  </Observer>
);
