import { AlertOutlined, DeleteOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Col, Collapse, Form, Input, Row, Select, Space, Steps, Tooltip, Typography } from 'antd';
import CheckBox from 'rc-checkbox';
import React, { useCallback, useState } from 'react';
import asyncModal from 'react-async-modal';

import { API, ICountry, ICreateStreamRequest, IStream } from '../../api';
import AdComboSettings from '../../assets/images/adcombo_settings.png';
import Modal from '../../components/modal';
import { BUTTON_DISABLED_TIME, colors } from '../../constants';
import { useCountries } from '../../hooks/countries';
import { useNamingMacros } from '../../hooks/macros';
import { useMyApps } from '../../hooks/myApps';
import { toast } from '../../modules/toast';

interface ICreateStreamModal {
  resolve(stream: IStream): void;
  appId?: number;
}

interface ISub {
  key: string;
  value: string;
  isCustom?: boolean;
  isCustomKey?: boolean;
  isHidden?: boolean;
  isBlocked?: boolean;
  isDisabled?: boolean;
}

let naming = '';

const callbackPurchaseLink = 'https://api.igaming.space/callback/purchase/{subacc_4}';
const callbackRegistrationLink = 'https://api.igaming.space/callback/registration/{subacc_4}';

const CreateStreamModal = ({ resolve, appId }: ICreateStreamModal) => {
  const [form] = Form.useForm();

  const apps = useMyApps();
  const namingMacros = useNamingMacros();
  const countries = useCountries();

  const [data, setData] = useState<{
    name?: string;
    appId?: number;
    url?: string;
    countryCodes?: ICountry['id'][];
  }>({
    appId,
  });

  const [subs, setSubs] = useState<ISub[]>([
    {
      key: 'subacc',
      value: '{stream}',
    },
    {
      key: 'subacc2',
      value: '{geo}',
    },
    {
      key: 'subacc3',
      value: '{ip}',
    },
    {
      key: 'subacc4',
      value: '{uid}',
      isBlocked: true,
    },
    {
      key: 'utm_source',
      value: '{source}',
    },
    {
      key: 'utm_medium',
      value: 'cpc',
      isCustom: true,
    },
    {
      key: 'utm_campaign',
      value: '{campaign}',
    },
    {
      key: 'utm_content',
      value: '{ad}',
    },
    {
      key: 'utm_term',
      value: '{platform}',
    },
  ]);

  const [isAdCombo, setIsAdCombo] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const previousStep = useCallback(() => {
    if (currentStep > 0) {
      setIsDisabled(true);
      setCurrentStep(currentStep - 1);

      setTimeout(() => {
        setIsDisabled(false);
      }, BUTTON_DISABLED_TIME);
    }
  }, [currentStep]);

  const nextStep = useCallback(() => {
    setIsDisabled(true);
    setCurrentStep(currentStep + 1);

    setTimeout(() => {
      setIsDisabled(false);
    }, BUTTON_DISABLED_TIME);
  }, [currentStep]);

  const createStream = useCallback(
    async (payload) => {
      try {
        setIsDisabled(true);
        const stream = await API.Streams.createStream({ ...payload, isActive: true } as ICreateStreamRequest);
        resolve(stream);
        toast.success('Поток успешно создан');
      } catch (err) {
        toast.error(err);
      } finally {
        setIsDisabled(false);
      }
    },
    [resolve]
  );

  const submit = useCallback(
    (formValues) => {
      const assign = { ...data, ...formValues };
      setData(assign);

      if (currentStep === 1 || currentStep === 3) {
        createStream(assign);
        return;
      }

      nextStep();
    },
    [currentStep, nextStep, data, createStream]
  );

  const changeOfferLink = useCallback((url: string) => {
    setIsAdCombo(url.includes('target=') || url.includes('rid='));
    setData((prevState) => ({ ...prevState, url }));
  }, []);

  const generateNaming = useCallback(() => {
    let elements: JSX.Element[] = [];

    subs.forEach(({ value, isCustom }) => {
      if (isCustom && new RegExp(/^{[a-zA-Z]+}$/).test(value)) {
        const tag = value.replace('{', '').replace('}', '');

        naming = naming + `${tag}_${tag.toUpperCase()}|`;

        elements.push(
          <span>
            {tag}_<span style={{ color: colors.red, fontWeight: 'bold' }}>{tag.toUpperCase()}</span>|
          </span>
        );
      }
    });

    return elements;
  }, [subs]);

  const addSub = useCallback(() => {
    setSubs([...subs, { isCustom: true, isCustomKey: true, value: '', key: '' }]);
  }, [subs]);

  const deleteCustomSub = useCallback(
    (index: number) => {
      const items = subs.filter((_, i) => i !== index);
      setSubs(items);
    },
    [subs]
  );

  const generateAdComboLink = useCallback(
    (subs: ISub[]) => {
      if (!data.url) return;

      const url = new URL(data.url);

      url.searchParams.forEach((value, key) => {
        url.searchParams.delete(key);
      });

      subs.forEach(({ key, value, isDisabled }) => {
        if (isDisabled) {
          url.searchParams.delete(key);
        } else {
          url.searchParams.set(key, value);
        }
      });

      setData((old) => ({ ...old, url: decodeURI(url.href) }));
    },
    [data.url]
  );

  const parseAdComboLink = useCallback(
    (link: string = '') => {
      const url = new URL(link);
      const urlParams = new URLSearchParams(url.search);

      let items = [...subs];

      urlParams.forEach((value, key) => {
        if (key === 'subacc4') return;

        const candidate = items.find((sub) => sub.key === key);

        if (candidate) {
          const index = items.indexOf(candidate);

          items[index] = { ...items[index], value, isCustom: true };

          if (namingMacros[value]) {
            items[index] = { ...items[index], value, isCustom: false };
          } else {
            items[index] = { ...items[index], value, isCustom: true };
          }
        } else {
          const item: ISub = { key, value, isCustom: true };

          if (key === 'target' || key === 'rid') {
            item.isHidden = true;
          }

          items = [...items, item];
        }
      });

      setSubs(items);
      generateAdComboLink(items);
    },
    [subs, namingMacros, generateAdComboLink]
  );

  const changeSub = useCallback(
    (index: number, value: string, isCustom: boolean = false) => {
      const subsCopy = [...subs];
      const subCopy = { ...subsCopy[index] };

      if (isCustom) {
        subCopy.value = value;
      } else if (value === '') {
        subCopy.isCustom = true;
        subCopy.value = '';
      } else if (!isCustom && value) {
        subCopy.isCustom = false;
        subCopy.value = value;
      } else {
        subCopy.value = value;
      }

      subsCopy[index] = subCopy;
      setSubs(subsCopy);

      generateAdComboLink(subsCopy);
    },
    [subs, generateAdComboLink]
  );

  const changeSubCustomKey = useCallback(
    (value: string, index: number) => {
      const items = [...subs];
      const item = { ...items[index] };

      item.key = value;
      items[index] = item;

      setSubs(items);

      generateAdComboLink(items);
    },
    [subs, generateAdComboLink]
  );

  const disableEnableSub = useCallback(
    (index) => {
      const items = [...subs];
      const item = { ...items[index] };

      item.isDisabled = !item.isDisabled;

      items[index] = item;
      setSubs(items);

      generateAdComboLink(items);
    },
    [subs, generateAdComboLink]
  );

  return (
    <Modal loading={false}>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          appId,
        }}
        onFinish={submit}
      >
        <Modal.Header title="Создание потока" />
        <Modal.Body>
          <Steps size="small" current={currentStep} style={{ marginBottom: 24 }}>
            <Steps.Step title="Шаг 1" />
            <Steps.Step title="Шаг 2" />
            {currentStep > 1 ? <Steps.Step title="Шаг 3" /> : null}
            {currentStep > 1 ? <Steps.Step title="Шаг 4" /> : null}
          </Steps>

          {currentStep === 0 && (
            <>
              <Typography.Paragraph strong>Введите название потока и выберите приложение</Typography.Paragraph>

              <Form.Item
                label="Название потока"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Пожалуйста, введите название потока',
                  },
                ]}
              >
                <Input placeholder="Красный поток" />
              </Form.Item>

              <Form.Item
                label="Приложение"
                name="appId"
                rules={[
                  {
                    required: true,
                    message: 'Пожалуйста, выберите приложение',
                  },
                ]}
              >
                <Select placeholder="Нажмите для выбора">
                  {apps.map((app) =>
                    app ? (
                      <Select.Option key={app.id} value={app.id}>
                        {app.title}
                      </Select.Option>
                    ) : null
                  )}
                </Select>
              </Form.Item>

              <Form.Item
                label="Список стран"
                name="countryCodes"
                rules={[
                  {
                    required: true,
                    message: 'Пожалуйста, выберите страны',
                  },
                ]}
              >
                <Select mode="multiple" placeholder="Перечислите страны">
                  {countries.map((country) =>
                    country ? (
                      <Select.Option key={country.id} value={country.id}>
                        [{country.id}] {country.name}
                      </Select.Option>
                    ) : null
                  )}
                </Select>
              </Form.Item>
            </>
          )}

          {currentStep === 1 ? (
            <>
              <Typography.Paragraph strong>
                Укажите ссылку на оффер, которая будет открываться пользователям в приложении
              </Typography.Paragraph>

              <Form.Item
                label="Ссылка на оффер"
                name="url"
                rules={[
                  {
                    type: 'url',
                    required: true,
                    message: 'Пожалуйста, введите ссылку на оффер',
                  },
                ]}
              >
                <Input
                  onChange={({ target: { value } }) => changeOfferLink(value)}
                  placeholder="https://onclickfund.com/kz2gamble?t4={sub1}&t5={sub2}&t6={sub3}"
                />
              </Form.Item>

              {isAdCombo && (
                <>
                  <Typography.Paragraph strong>
                    Мы определили, что указанная Вами ссылка принадлежит офферу из ПП AdCombo.com
                  </Typography.Paragraph>

                  <Space direction="vertical">
                    <Button
                      onClick={() => {
                        parseAdComboLink(data.url);
                        nextStep();
                      }}
                      disabled={isDisabled}
                      style={{ width: 400 }}
                      type="primary"
                    >
                      Продолжить настройку по рекомендациям AdCombo
                    </Button>
                    <Button disabled={isDisabled} htmlType="submit" style={{ width: 400 }}>
                      Настроить нейминг самостоятельно
                    </Button>
                  </Space>
                </>
              )}
            </>
          ) : null}

          {currentStep === 2 ? (
            <>
              <Typography.Paragraph strong>
                Пример нейминга, который нужно вставить вместо названия рекламной кампании в источнике трафика: *
              </Typography.Paragraph>

              <Card>
                <Space>
                  <Typography.Text>
                    <Typography.Text>ID_потока</Typography.Text>|{generateNaming().map((element) => element)}
                  </Typography.Text>
                  {/*<Tooltip title="Скопировать в буфер">*/}
                  {/*  <Button*/}
                  {/*    type="primary"*/}
                  {/*    size="small"*/}
                  {/*    icon={<CopyOutlined />}*/}
                  {/*    onClick={() => copyToClipboard(data.appId + '|' + naming)}*/}
                  {/*  />*/}
                  {/*</Tooltip>*/}
                </Space>
                {generateNaming().length ? (
                  <Alert
                    style={{ marginTop: 16 }}
                    showIcon
                    icon={<AlertOutlined />}
                    type="warning"
                    message="Выделенное красным поменяйте на свои значения"
                  />
                ) : null}
              </Card>

              <Typography.Text type="secondary" style={{ marginTop: 4 }}>
                * Готовый нейминг сможете скопировать после создания потока
              </Typography.Text>

              <br />

              <Typography.Paragraph strong>
                Вы можете всё оставить как есть, а можете самостоятельно настроить передачу параметров
              </Typography.Paragraph>

              <Collapse>
                <Collapse.Panel header="Настройка параметров" key="1">
                  {subs.map((sub, index) =>
                    sub.isHidden ? null : (
                      <Row key={index}>
                        <Col span={1}>
                          <Tooltip title={sub.isDisabled ? 'Добавить в ссылку' : 'Убрать из ссылки'}>
                            <Form.Item style={{ width: 14 }}>
                              <CheckBox checked={!sub.isDisabled} onChange={() => disableEnableSub(index)} />
                            </Form.Item>
                          </Tooltip>
                        </Col>

                        <Col span={5}>
                          {sub.isCustomKey ? (
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: 'Пожалуйста, заполните',
                                },
                              ]}
                            >
                              <Input
                                placeholder="Заполните"
                                onChange={({ target: { value } }) => changeSubCustomKey(value, index)}
                                value={sub.key}
                              />
                            </Form.Item>
                          ) : (
                            <Typography.Text style={{ marginTop: 2, display: 'block' }}>{sub.key}</Typography.Text>
                          )}
                        </Col>
                        <Col span={8} offset={1}>
                          <Form.Item>
                            <Select
                              disabled={sub.isBlocked}
                              onChange={(value) => changeSub(index, value)}
                              value={sub.isCustom ? '' : sub.value}
                            >
                              <Select.Option value="">Custom - произвольный</Select.Option>
                              {Object.entries(namingMacros).map(([key, name], index) => (
                                <Select.Option value={key} key={index}>
                                  {key} - {name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        {sub.isCustom ? (
                          <Col span={sub.isCustomKey ? 6 : 8} offset={1}>
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: 'Пожалуйста, заполните',
                                },
                              ]}
                            >
                              <Input
                                placeholder="Заполните"
                                onChange={({ target: { value } }) => changeSub(index, value, true)}
                                value={sub.value}
                              />
                            </Form.Item>
                          </Col>
                        ) : null}

                        {sub.isCustomKey ? (
                          <Col span={1} offset={sub.isCustom ? 1 : 8}>
                            <Button onClick={() => deleteCustomSub(index)} danger icon={<DeleteOutlined />} />
                          </Col>
                        ) : null}
                      </Row>
                    )
                  )}
                  <Button onClick={addSub}>Добавить еще один параметр</Button>
                </Collapse.Panel>
              </Collapse>

              <Typography.Paragraph style={{ marginTop: 18 }}>Ссылка на оффер</Typography.Paragraph>
              <Typography.Text copyable type="secondary">
                {data.url}
              </Typography.Text>
            </>
          ) : null}

          {currentStep === 3 ? (
            <>
              <Typography.Paragraph strong>Пропишите постбэки в AdCombo</Typography.Paragraph>
              <Typography.Paragraph>
                Постбеки можно проставить в разделах: <br />
                <Space>
                  <Typography.Link target="_blank" href="https://my.adcombo.com/offers">
                    https://my.adcombo.com/offers
                  </Typography.Link>
                  или
                  <Typography.Link target="_blank" href="https://my.adcombo.com/my-campaigns">
                    https://my.adcombo.com/my-campaigns
                  </Typography.Link>
                </Space>
              </Typography.Paragraph>

              <Typography.Paragraph strong>Ваши постбэки:</Typography.Paragraph>
              <Typography.Paragraph>
                В поле "postback url confirm initial"
                <br />
                <Typography.Text copyable>{callbackRegistrationLink}</Typography.Text>
              </Typography.Paragraph>
              <Typography.Paragraph>
                В поле "postback url confirm complete"
                <br />
                <Typography.Text copyable>{callbackPurchaseLink}</Typography.Text>
              </Typography.Paragraph>

              <Typography.Paragraph>Пример:</Typography.Paragraph>
              <img src={AdComboSettings} alt="AdCombo settings" />
            </>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          {currentStep > 0 ? (
            <Button disabled={isDisabled} onClick={previousStep} type="dashed">
              Назад
            </Button>
          ) : null}

          {currentStep === 0 || currentStep === 2 ? (
            <Button disabled={isDisabled} style={{ float: 'right' }} htmlType="submit" type="primary">
              Следующий шаг
            </Button>
          ) : null}

          {(currentStep === 1 && !isAdCombo) || currentStep === 3 ? (
            <Button disabled={isDisabled} style={{ float: 'right' }} htmlType="submit" type="primary">
              Создать поток
            </Button>
          ) : null}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export const openCreateStreamModal = (appId?: number) =>
  asyncModal(
    CreateStreamModal,
    { appId },
    {
      closeOnOverlayClick: false,
      styles: {
        modal: {
          width: 700,
        },
      },
    }
  );
