import './index.scss';

import { DeleteOutlined, NotificationOutlined } from '@ant-design/icons';
import { Avatar, Badge, Button, Dropdown, Menu, Space, Typography } from 'antd';
import block from 'bem-cn';
import { runInAction } from 'mobx';
import { Observer } from 'mobx-react';
import moment from 'moment';
import React, { SyntheticEvent } from 'react';

import { API } from '../../api';
import { store } from '../../store';

const b = block('notifications');

const readNotification = async (e: SyntheticEvent, id: number, index: number): Promise<void> => {
  e.stopPropagation();

  runInAction(() => {
    store.notifications[index].isNew = false;
  });

  await API.Notifications.readNotification(id);
};

const readAllNotifications = async (): Promise<void> => {
  runInAction(() => {
    store.notifications = store.notifications.map((n) => ({ ...n, isNew: false }));
  });

  await API.Notifications.readAllNotification();
};

const deleteNotification = async (e: SyntheticEvent, id: number): Promise<void> => {
  e.stopPropagation();

  runInAction(() => {
    store.notifications = store.notifications.filter((n) => n.id !== id);
  });

  await API.Notifications.deleteNotification(id);
};

const deleteAllNotifications = async (): Promise<void> => {
  runInAction(() => {
    store.notifications = [];
  });

  await API.Notifications.deleteAllNotifications();
};

export const Notifications = () => {
  return (
    <Observer>
      {() => {
        const count = store.notifications.filter((n) => n.isNew).length;
        const notifications = store.notifications;

        return (
          <div className={b()}>
            <Dropdown
              overlayClassName={b('overlay').toString()}
              trigger={['click']}
              overlay={() => (
                <Menu className={b('list').toString()}>
                  {notifications.length ? (
                    <>
                      {notifications.map((notification, index) => (
                        <Menu.Item
                          onClick={(e) => readNotification(e.domEvent, notification.id, index)}
                          isSelected={false}
                          key={notification.id}
                        >
                          <Space align="start">
                            <Avatar
                              src={notification.icon ? notification.icon.url : notification.message[0].toUpperCase()}
                            />
                            <Typography.Text type={notification.isNew ? undefined : 'secondary'}>
                              {notification.message}
                              <br />
                              <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                                {moment(notification.createdAt).fromNow()}
                              </Typography.Text>
                            </Typography.Text>
                            <Button
                              onClick={(e) => deleteNotification(e, notification.id)}
                              icon={<DeleteOutlined />}
                              size="small"
                            />
                          </Space>
                        </Menu.Item>
                      ))}

                      <div className={b('footer')}>
                        <Button onClick={deleteAllNotifications} className={b('link').toString()} type="link">
                          Удалить все
                        </Button>
                        <Button onClick={readAllNotifications} className={b('link').toString()} type="link">
                          Просмотреть все
                        </Button>
                      </div>
                    </>
                  ) : (
                    <Menu.Item>
                      <Typography.Text type="secondary">Нет оповещений</Typography.Text>
                    </Menu.Item>
                  )}
                </Menu>
              )}
              placement="bottomRight"
            >
              <Badge overflowCount={9} count={count}>
                <Button type="primary" icon={<NotificationOutlined />} />
              </Badge>
            </Dropdown>
          </div>
        );
      }}
    </Observer>
  );
};
