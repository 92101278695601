import { TUserRole } from '../../api';
import { store } from '../../store';

export const isGranted = (role: TUserRole | TUserRole[], component?: any): boolean | null => {
  const { user } = store;

  if (!user) {
    return component ? null : false;
  }

  if (Array.isArray(role) && role.includes(user.role)) {
    return component || true;
  }

  if (role === user.role) {
    return component || true;
  }

  return component ? null : false;
};
