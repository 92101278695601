import { Button } from 'antd';

const ResetFilters = () => {
  const reset = () => {
    localStorage.removeItem(window.location.pathname);
    window.location.reload();
  };
  return <Button onClick={reset}>Сбросить фильтры</Button>;
};

export default ResetFilters;
